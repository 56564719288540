import NeedsService from "./needs.service";
import PrintAndCopyService from "./print.and.copy.service";
import ErrorService from "./error.service";
import $ from "jquery";
import moment from "moment-timezone";
import WinnersService from "./chart/winners.service";
import GoogleService from "./google.service";
import AlertService from "./alert.service";
const EMPTY_CELL = "--";
var novalue = "--";
class TableService {
  companyColorList = [];
  moatMapping = [
    { shortName: "Innovation", longName: "Distinct Innovation" },
    { shortName: "Brand", longName: "Brand Perception" },
    { shortName: "Time", longName: "Time Authenticity" },
    { shortName: "Network", longName: "Network Effect" },
    { shortName: "Routine", longName: "Routine Reliance" },
    { shortName: "Lock-In", longName: "Switching Lock-In" },
    { shortName: "Lock-Out", longName: "Channel Lock-Out" },
    { shortName: "Skill", longName: "Economies of Skill" },
    { shortName: "Scale", longName: "Economies of Scale" },
    { shortName: "Design", longName: "Design Dominance" },
    { shortName: "Value", longName: "Value Chain" }
  ];
  getTableData(table){
		try{
      let rowData = table.rows().data().toArray()
      let columnData = [];
      let initialColumnData = table.settings().init().columns;
      for (let index = 0; index < initialColumnData.length; index++) {
        let title = initialColumnData[index].title;
        if (typeof title == "undefined"){
          title = initialColumnData[index].sName;
        }
        columnData.push(title);
        
      }    
      let tableData = {
        rows: rowData,
        columns: columnData,
        companyColorList: this.companyColorList
      }
      return JSON.stringify(tableData);
    } catch (err) {
      ErrorService.logException("TableService.getTableData", err, "chart");
    }
  }  
  getEmptyCell(){
    return EMPTY_CELL;
  }
  nonEmptyAsc(a, b){
    let x = a;
    let y = b;

    if (x == novalue && y != novalue) {
      return -1;
    } else if (x != novalue && y == novalue) {
      return 1;
    } else if (x == novalue && y == novalue) {
      return 0;
    } else if (x != novalue && y != novalue) {
      x = parseFloat(a);
      y = parseFloat(b);

      return x < y ? -1 : x > y ? 1 : 0;
    }

  }
  nonEmptyDesc(a, b){
    let x = a;
    let y = b;

    if (x == novalue && y != novalue) {
      return 1;
    } else if (x != novalue && y == novalue) {
      return -1;
    } else if (x == novalue && y == novalue) {
      return 0;
    } else if (x != novalue && y != novalue) {
      x = parseFloat(a);
      y = parseFloat(b);

      return x < y ? 1 : x > y ? -1 : 0;
    }

  }
  getDateColumn(name) {
    return {
      data: name,
      type: "date",
      name: name,
      width: "7rem",
      render: function(data, type, row) {
        return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
      }
    };
  }
  getActiveColumn(name) {
    return {
      data: "active",
      name: "status",
      width: "6rem",
      render: function(data, type, row) {
        var statusMap = { N: { label: "Deactive", style: "warning" }, Y: { label: "Active", style: "success" } };
        if (type === "display") {
          return "<p class='m-0 p-0' style='width: 4rem;'><span class='badge badge-" + statusMap[data].style + "'>" + statusMap[data].label + "</span></p>";
        } else {
          return data;
        }
      }
    };
  }
  getTableButtons(vm, dataContainerId, insightModalIdOverride, reportPageTypeNameOverride,isSavedCard = true) {
    let buttonConfiguration = {
      buttons: [
        {
          text: "<i class='fa-duotone fa-camera fa-lg'></i>",
          className: "btn",
          titleAttr: "Take snapshot of the table",
          action: function(e, dt, node, config) {
              AlertService.showInProgressMessage(PrintAndCopyService.getSnapMessage(),'Snap in progress....');
              setTimeout(function() {
                GoogleService.logClickEvent(vm.reportPageTypeName, "Snap", 0, vm);
                const vm2nd = vm;
                PrintAndCopyService.snapChart(dataContainerId, vm, false, true, false).then(function() {
                  AlertService.closeAlert(0);
                });
              }, 1000);
          }
        },
        { extend: "csv", text: "<i class='fa-duotone fa-download fa-lg'></i>", className: "btn", titleAttr: "Download data" ,

          exportOptions : {
              modifier : {
                  // DataTables core
                  order : 'current',  // 'current', 'applied', 'index',  'original'
                  page : 'all',      // 'all',     'current'
                  search : 'applied'     // 'none',    'applied', 'removed'
              }
          }
        },
        { extend: "copy", text: "<i class='fad fa-clipboard-list-check fa-lg'></i>", className: "btn", titleAttr: "Clip and paste data" },
      ]
    };
    let insightsButton = {
        text: "<i class='fa-duotone fa-thought-bubble fa-lg'></i>",
        className: "btn",
        titleAttr: "Insights",
        action: function(e, dt, node, config) {
          let insightModalId = typeof insightModalIdOverride != "undefined" ? insightModalIdOverride : vm.insightModalId;
          let reportPageTypeName = typeof reportPageTypeNameOverride != "undefined" ? reportPageTypeNameOverride : vm.reportPageTypeName;
          if (typeof insightModalIdOverride != "undefined") {
            vm.displayInsights(insightModalId, reportPageTypeName);
          } else {
            vm.$parent.displayInsights(insightModalId, reportPageTypeName);
          }
          vm.$gtag.event("click", vm.reportPageTypeName,  "Insights", 0);
          GoogleService.logClickEvent(vm.reportPageTypeName, "Insights", 0, vm);
        }
    };
    if (isSavedCard) {
      buttonConfiguration.buttons.push(insightsButton);
    }
    return buttonConfiguration;
  }
  getMoatLongName(shortName) {
    let searchableMoat = this.moatMapping.find(x => x.shortName == shortName);
    if (typeof searchableMoat != "undefined") {
      return searchableMoat.longName;
    } else {
      return "";
    }
  }
  setCompanyColorList(list) {
    this.companyColorList = list;
  }
  getCompanyColorList(companyName) {
    let searchableCompany = this.companyColorList.find(x => x.companyName == companyName);
    if (typeof searchableCompany != "undefined") {
      return searchableCompany.color;
    } else {
      return "";
    }
  }
  getMoatNameTableAttribute(position) {
    return {
      targets: position,
      className: "dt-left",
      width: 190,
      render: function(data, type, row, meta) {
        var parameters = meta.settings.oInit.columnDefs[meta.col].parameters;
        let fieldNumber = meta.row + 1;
        if (type === "display") {
          let originalData = data;
          if (originalData != "--") {
            fieldNumber = NeedsService.getMoatNumber(data);
            if (fieldNumber == 0) {
              fieldNumber = meta.row + 1;
            }
          }
          let needName = "Moat " + fieldNumber;
          data = '<span class="popUp" data-modal-name="' + needName + '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>' + data;
        }
        return data;
      }
    };
  }
  getMainMoatNameTableAttribute(position) {
    return {
      targets: position,
      className: "dt-left",
      width: 90,
      render: function(data, type, row, meta) {
        return data;
      }
    };
  }
  getFunctionalNeedNameTableAttribute(position) {
    return {
      targets: position,
      className: "dt-left",
      width: 130,
      render: function(data, type, row, meta) {
        let dataLengthMax = 40;
        var parameters = meta.settings.oInit.columnDefs[meta.col].parameters;
        let originalData = data;
        data = data.length > dataLengthMax ? data.substr(0, dataLengthMax) + "…" : data;
        let displayData = data;
        let includeToolTip = data.length > dataLengthMax;
        if (type === "display" && originalData != "Average") {
          let fieldNumber = NeedsService.getNeedNumber(data, "functional");
          if (fieldNumber == 0) {
            fieldNumber = meta.row + 1;
          } else if (fieldNumber > 12) {
            return data;
          }
          let needName = "Functional Need " + fieldNumber;
          data =
            '<div class="my_ellipse"><span class="popUp"  data-modal-name="' + needName + '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>';
          if (includeToolTip) {
            data += '<span data-toggle="tooltip" title="' + originalData + '">' + displayData + "</span>";
          } else {
            data += displayData;
          }
          data += "</div>";
        }
        return data;
      }
    };
  }
  getEmotionalNeedNameTableAttribute(position) {
    return {
      targets: position,
      className: "dt-left",
      width: 170,
      render: function(data, type, row, meta) {
        let dataLengthMax = 40;
        let originalData = data;
        if (type === "display" && originalData != "Average") {
          data = data.length > dataLengthMax ? data.substr(0, dataLengthMax) + "…" : data;
          let displayData = data;
          let includeToolTip = data.length > dataLengthMax;
          let fieldNumber = NeedsService.getNeedNumber(data, "emotional");
          if (fieldNumber == 0) {
            fieldNumber = meta.row + 1;
          } else if (fieldNumber > 5) {
            return data;
          }
          let needName = "Emotional Need " + fieldNumber;
          data =
            '<div class="my_ellipse"><span class="popUp" data-modal-name="' + needName + '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>';
          if (includeToolTip) {
            data += '<span data-toggle="tooltip" title="' + originalData + '">' + displayData + "</span>";
          } else {
            data += displayData;
          }
          data += "</div>";
        }
        return data;
      }
    };
  }
  getBaseColumn(position, className, width){
    return {
      targets: position,
      width: width,
      className: className,
      render(v) {
        return v;
      }
    };
  }  
  getNumericTableAttribute(position, className, width) {
    return {
      targets: position,
      width: width,
      className: className,
      type: 'non-empty-string',
      render(v) {
        if (v != null && !isNaN(v)) {
          return Number(v).toFixed(2);
        } else {
          return EMPTY_CELL;
        }
      }
    };
  }  
  getNumericTableAttributeRanks(position, className, width, defaultValue = EMPTY_CELL) {
    return {
      targets: position,
      width: width,
      className: className,
      type: 'non-empty-string',
      render(v) {
        if (v != null && !isNaN(v) && v != "") {
          return Number(v).toFixed(3);
        } else {
          return defaultValue;
        }
      }
    };
  }
  getDollarTableAttribute(position, className, width, precision) {
    return {
      targets: position,
      width: width,
      className: className,
      type: 'non-empty-string',
      render(v) {
        if (v != null && !isNaN(v)) {
          return "$ " + Number(v).toFixed(precision);
        } else {
          return EMPTY_CELL;
        }
      }
    };
  }

  getNPSTableAttribute(position, className, width) {
    return {
      targets: position,
      width: width,
      className: className,
      render(v) {
        if (v != null && !isNaN(v) && v == ".0") {
          return Number(v).toFixed(1);
        } else {
          return v;
        }
      }
    }
  }
  getRankTableAttribute(position, className, width) {
    return {
      targets: position,
      width: width,
      className: className,
      type: 'non-empty-string',
      render(v) {
        if (v != null && !isNaN(v) && v != 0) {
          return v;
        } else {
          return " ";
        }
      }
    };
  }
  getRankPercentTableAttribute(position, className, width, precision) {
    return {
      targets: position,
      width: width,
      className: className,
      type: 'non-empty-string',
      render(v) {
        if (v != null && !isNaN(v) && v != 0) {
          return Number(v).toFixed(precision) + "%";
        } else {
          return " ";
        }
      }
    };
  }
  getMoneyTableAttribute(position, className, width, precision) {
    return {
      targets: position,
      width: width,
      className: className,
      type: 'non-empty-string',
      render(v) {
        if (v != null && !isNaN(v)) {
          return "$"+Number(v).toFixed(precision);
        } else {
          return EMPTY_CELL
        }
      }
    };
  }
  getPercentTableAttribute(position, className, width, precision) {
    return {
      targets: position,
      width: width,
      className: className,
      type: 'non-empty-string',
      render(v) {
        if (v != null && !isNaN(v)) {
          return Number(v).toFixed(precision) + "%";
        } else {
          return EMPTY_CELL
        }
      }
    };
  }
  getPercentTableAttributeRanks(position, className, width, precision, defaultValue = EMPTY_CELL) {
    return {
      targets: position,
      width: width,
      className: className,
      type: 'non-empty-string',
      render(v) {
        if (v != null && !isNaN(v) && v != "") {
          return Number(v).toFixed(precision) + "%";
        } else {
          return defaultValue
        }
      }
    };
  }
  getBaseTableAttribute(position, className, width) {
    return {
      targets: position,
      className: className,
      width: width,
      render: function(data, type, row, meta) {
        return data;
      }
    };
  }
  getOrderSequence() {
    return { orderSequence: ["desc", "asc"], targets: ["_all"] };
  }
  setRowColor(row, rowColor) {
    row.css("color", rowColor);
  }
  setHeaderColor(column) {
    let columnText = column.text();
    let columnColor = this.getCompanyColorList(columnText);
    if (columnColor != "") {
      column.css("background-color", columnColor);
      column.css("color", "#fff");
    }
  }
  getManageInvitationsTableColumnAttributes() {
    return [
      {
        title: "Unique Code Id",
        data: "surveyUniqueCodeId",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
        className: "",
        name: "id",
        width: "3rem"
      },
      { title: "Survey Name", data: "surveyName", name: "surveyName", width: "5rem" },
      { title: "Email Address", data: "emailAddress", name: "emailAddress", width: "7rem" },
      { title: "Unique Code", data: "uniqueCode", name: "uniqueCode", width: "5rem" },
      { title: "Url", data: "url", name: "url", width: "15rem" },
      {
        title: "Status",
        data: "status",
        className: "",
        name: "status",
        width: "3rem",
        render: function(data, type, row) {
          var statusMap = { C: { label: "Complete", style: "warning" }, R: { label: "Ready", style: "success" } };
          if (type === "display") {
            return "<p class='m-0 p-0' style='width: 4rem;'><span class='badge badge-" + statusMap[data].style + "'>" + statusMap[data].label + "</span></p>";
          } else {
            return data;
          }
        }
      },
      {
        title: "Date Created",
        data: "dateCreated",
        type: "date",
        name: "dateCreated",
        class: "text-left",
        width: "4rem",
        render: function(data, type, row) {
          if (data != null) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY");
          } else {
            return "";
          }
        }
      },
      {
        title: "Date Completed",
        data: "dateCompleted",
        type: "date",
        name: "dateCompleted",
        class: "text-left",
        width: "4rem",
        render: function(data, type, row) {
          if (data != null) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          } else {
            return "";
          }          
        }
      }
    ];
  }
  getNpsTableColumnAttributes() {
    return [
      this.getBaseTableAttribute([0], "dt-left", 130),
      this.getNPSTableAttribute([1], "dt-right", 50),
      {
        targets: "_all",
        width: 50,
        className: "dt-right",
        render(v) {
          if (v != null && !isNaN(v) && v == ".0") {
            return Number(v).toFixed(1);
          } else {
            return v;
          }
        }
      }
    ];
  }
  getTableColumnAttributes(source) {
    if (source == "Match By Value") {
      return {
        columnDefsEmotional: [
          this.getOrderSequence(),
          {
            targets: [0],
            width: 150,
            className: "dt-left",
            render: function(data, type, row, meta) {
              return '<div class="my_ellipse">' + data + "</div>";
            }
          },
          {
            targets: [1],
            className: "dt-left",
            width: 190,
            render: function(data, type, row, meta) {
              var parameters = meta.settings.oInit.columnDefs[meta.col].parameters;
              let originalData = data;
              data = data.length > 40 ? data.substr(0, 40) + "…" : data;
              let displayData = data;
              let includeToolTip = data.length > 40;

              if (type === "display") {
                let fieldNumber = NeedsService.getNeedNumber(data, "emotional");
                if (fieldNumber == 0) {
                  fieldNumber = meta.row + 1;
                } else if (fieldNumber > 5) {
                  return data;
                }
                let needName = "Emotional Need " + fieldNumber;
                data =
                  '<div class="my_ellipse"><span class="popUp"  data-modal-name="' +
                  needName +
                  '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>';
                if (includeToolTip) {
                  data += '<span data-toggle="tooltip" title="' + originalData + '">' + displayData + "</span>";
                } else {
                  data += displayData;
                }
                data += "</div>";
              }
              return data;
            }
          },
          this.getNumericTableAttribute([2], "dt-right", 50),
          this.getNumericTableAttribute([3], "dt-right", 50),
          this.getNumericTableAttribute(["_all"], "dt-right", 50)
        ],
        columnDefsFunctional: [
          this.getOrderSequence(),
          {
            targets: [0],
            className: "dt-left",
            width: 50,
            type: 'non-empty-string',
            render(v) {
              if (v != null && !isNaN(v)) {
                if (v == 0) {
                  return EMPTY_CELL;
                }
                v = v * 100;
                return Number(v).toFixed(1) + "%";
              } else {
                return EMPTY_CELL;
              }
            }
          },
          {
            targets: [1],
            width: 150,
            className: "dt-left",
            render: function(data, type, row, meta) {
              return data;
            }
          },
          {
            targets: [2],
            className: "dt-left data-table-border-right",
            width: 190,
            render: function(data, type, row, meta) {
              var parameters = meta.settings.oInit.columnDefs[meta.col].parameters;
              let originalData = data;
              data = data.length > 40 ? data.substr(0, 40) + "…" : data;
              let displayData = data;
              let includeToolTip = data.length > 40;

              if (type === "display") {
                let fieldNumber = NeedsService.getNeedNumber(data, "functional");
                if (fieldNumber == 0) {
                  fieldNumber = meta.row + 1;
                } else if (fieldNumber > 12) {
                  return data;
                }
                let needName = "Functional Need " + fieldNumber;
                data =
                  '<div class="my_ellipse"><span class="popUp"  data-modal-name="' +
                  needName +
                  '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>';
                if (includeToolTip) {
                  data += '<span data-toggle="tooltip" title="' + originalData + '">' + displayData + "</span>";
                } else {
                  data += displayData;
                }
                data += "</div>";
              }
              return data;
            }
          },
          this.getNumericTableAttribute([3], "dt-right", 50),
          this.getNumericTableAttribute([4], "dt-right", 50),
          this.getNumericTableAttribute([5], "dt-right", 50),
          this.getNumericTableAttribute([-1], "dt-right", 50, 1),
          this.getNumericTableAttribute(["_all"], "dt-right", 50)
        ]
      };
    } else if (source == "Profile By Value") {
      return {
        columnDefsEmotional: [
          this.getOrderSequence(),
          {
            targets: [0],
            width: 150,
            className: "dt-left",
            render: function(data, type, row, meta) {
              if (type === "display") {
                return '<div class="my_ellipse">' + data + "</div>";
              }
              return data;
            }
          },
          {
            targets: [1],
            className: "dt-left",
            width: 190,
            render: function(data, type, row, meta) {
              var parameters = meta.settings.oInit.columnDefs[meta.col].parameters;
              let originalData = data;
              data = data.length > 40 ? data.substr(0, 40) + "…" : data;
              let displayData = data;
              let includeToolTip = data.length > 40;

              if (type === "display") {
                let fieldNumber = NeedsService.getNeedNumber(data, "emotional");
                if (fieldNumber == 0) {
                  fieldNumber = meta.row + 1;
                } else if (fieldNumber > 5) {
                  return data;
                }
                let needName = "Emotional Need " + fieldNumber;
                data =
                  '<div class="my_ellipse"><span class="popUp"  data-modal-name="' +
                  needName +
                  '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>';
                if (includeToolTip) {
                  data += '<span data-toggle="tooltip" title="' + originalData + '">' + displayData + "</span>";
                } else {
                  data += displayData;
                }
                data += "</div>";
              }
              return data;
            }
          },
          this.getNumericTableAttribute([2], "dt-right", 50),
          this.getNumericTableAttribute([3], "dt-right", 50),
          this.getNumericTableAttribute([4], "dt-right", 50),
          this.getNumericTableAttribute([-1], "dt-right", 50, 2),
          this.getPercentTableAttribute([-2], "dt-right", 50, 2),
          this.getNumericTableAttribute(["_all"], "dt-right", 50)
        ],
        columnDefsFunctional: [
          this.getOrderSequence(),
          {
            targets: [0],
            className: "dt-left",
            width: 50,
            type: 'non-empty-string',
            render(v) {
              if (v != null && !isNaN(v)) {
                if (v == 0) {
                  return EMPTY_CELL;
                }
                v = v * 100;
                return Number(v).toFixed(1) + "%";
              } else {
                return EMPTY_CELL;
              }
            }
          },
          {
            targets: [1],
            width: 150,
            className: "dt-left",
            render: function(data, type, row, meta) {
              return data;
            }
          },
          {
            targets: [2],
            className: "dt-left",
            width: 190,
            render: function(data, type, row, meta) {
              var parameters = meta.settings.oInit.columnDefs[meta.col].parameters;
              let originalData = data;
              data = data.length > 40 ? data.substr(0, 40) + "…" : data;
              let displayData = data;
              let includeToolTip = data.length > 40;

              if (type === "display") {
                let fieldNumber = NeedsService.getNeedNumber(data, "functional");
                if (fieldNumber == 0) {
                  fieldNumber = meta.row + 1;
                } else if (fieldNumber > 12) {
                  return data;
                }
                let needName = "Functional Need " + fieldNumber;
                data =
                  '<div class="my_ellipse"><span class="popUp"  data-modal-name="' +
                  needName +
                  '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>';
                if (includeToolTip) {
                  data += '<span data-toggle="tooltip" title="' + originalData + '">' + displayData + "</span>";
                } else {
                  data += displayData;
                }
                data += "</div>";
              }
              return data;
            }
          },
          this.getNumericTableAttribute([3], "dt-right", 50),
          this.getNumericTableAttribute([4], "dt-right", 50),
          this.getNumericTableAttribute([5], "dt-right ", 50),
          this.getPercentTableAttribute([-2], "dt-right", 50, 2),
          this.getNumericTableAttribute([-1], "dt-right", 50, 2),
          this.getNumericTableAttribute(["_all"], "dt-right", 50)
        ]
      };
    } else if (source == "Profile By Winners") {
      return WinnersService.getTableColumnAttributes();
    } else if (source == "Profile By Moat") {
      return {
        columnDefs: [
          this.getOrderSequence(),
          {
            targets: [0],
            className: "dt-left",
            width: 120,
            render: function(data, type, row, meta) {
              var parameters = meta.settings.oInit.columnDefs[meta.col].parameters;
              if (type === "display") {
                let originalData = data;
                if (originalData != "--") {
                  let fieldNumber = NeedsService.getMoatNumber(data);
                  if (fieldNumber == 0) {
                    fieldNumber = meta.row + 1;
                  }
                  let needName = fieldNumber != -1 ? "Moat " + fieldNumber : data ;
                  data = '<span class="popUp" data-modal-name="' + needName + '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>' + data;
                }
                return data;
              }
              return data;
            }
          },
          {
            targets: [1],
            className: "dt-left",
            width: 190,
            render: function(data, type, row, meta) {
              var parameters = meta.settings.oInit.columnDefs[meta.col].parameters;
              let originalData = data;
              data = data.length > 40 ? data.substr(0, 40) + "…" : data;
              let displayData = data;
              let includeToolTip = data.length > 40;

              if (type === "display") {
                let isEmotional = data.includes(",");
                let needType = isEmotional ? "emotional" : "functional";
                let fieldNumber = NeedsService.getNeedNumber(data, needType);
                if (fieldNumber == 0) {
                  fieldNumber = meta.row + 1;
                } else if (fieldNumber > 12) {
                  return data;
                }
                let needName = isEmotional ? "Emotional Need " : "Functional Need ";
                needName += fieldNumber;
                data = '<span class="popUp"  data-modal-name="' + needName + '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>';
                if (includeToolTip) {
                  data += '<span data-toggle="tooltip" title="' + originalData + '">' + displayData + "</span>";
                } else {
                  data += displayData;
                }
              }
              return data;
            }
          },
          this.getNumericTableAttribute([2], "dt-right", 50),
          this.getNumericTableAttribute([3], "dt-right", 50),
          this.getNumericTableAttribute([4], "dt-right ", 50),
          this.getPercentTableAttribute([-2], "dt-right", 50, 2),
          this.getNumericTableAttribute(["_all"], "dt-right", 50)
        ]
      };
    } else if (source == "Match By Moat") {
      return {
        columnDefs: [
          this.getOrderSequence(),
          {
            targets: [0],
            className: "dt-left",
            width: 120,
            render: function(data, type, row, meta) {
              var parameters = meta.settings.oInit.columnDefs[meta.col].parameters;
              if (type === "display") {
                let originalData = data;
                if (originalData != "--") {
                  let fieldNumber = NeedsService.getMoatNumber(data);
                  if (fieldNumber == 0) {
                    fieldNumber = meta.row + 1;
                  }
                  let needName = fieldNumber != -1 ? "Moat " + fieldNumber : data;
                  data = '<span class="popUp" data-modal-name="' + needName + '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>' + data;
                }
                return data;
              }
              return data;
            }
          },
          {
            targets: [1],
            className: "dt-left",
            width: 190,
            render: function(data, type, row, meta) {
              var parameters = meta.settings.oInit.columnDefs[meta.col].parameters;
              let originalData = data;
              data = data.length > 40 ? data.substr(0, 40) + "…" : data;
              let displayData = data;
              let includeToolTip = data.length > 40;

              if (type === "display") {
                let isEmotional = data.includes(",");
                let needType = isEmotional ? "emotional" : "functional";
                let fieldNumber = NeedsService.getNeedNumber(data, needType);
                if (fieldNumber == 0) {
                  fieldNumber = meta.row + 1;
                } else if (fieldNumber > 12) {
                  return data;
                }
                let needName = isEmotional ? "Emotional Need " : "Functional Need ";
                needName += fieldNumber;
                data = '<span class="popUp"  data-modal-name="' + needName + '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>';
                if (includeToolTip) {
                  data += '<span data-toggle="tooltip" title="' + originalData + '">' + displayData + "</span>";
                } else {
                  data += displayData;
                }
              }
              return data;
            }
          },
          this.getNumericTableAttribute([2], "dt-right ", 50),
          this.getNumericTableAttribute([3], "dt-right ", 50),
          this.getNumericTableAttribute([4], "dt-right ", 50),
          this.getNumericTableAttribute(["_all"], "dt-right", 50)
        ]
      };
    } else if (source == "Match By Winners") {
      return {
        columnDefs: [
          this.getOrderSequence(),
          {
            targets: [0],
            className: "dt-left",
            width: 120,
            render: function(data, type, row, meta) {
              var parameters = meta.settings.oInit.columnDefs[meta.col].parameters;
              if (type === "display") {
                let originalData = data;
                let headerForTableStakes = "Table Stakes - Header";
                let headerForCore = "Core - Header";
                let headerForDiverge = "Diverge - Header";
                let firstInstanceOfTableStakes = true;
                let firstInstanceOfCore = true;
                let firstInstanceOfDiverge = true;
  
                if (originalData != "--" && headerForTableStakes == data && firstInstanceOfTableStakes) {
                  data = "Table Stakes";
                  data = '<span class="popUp" data-modal-name="' + data + '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>' + data;
                  firstInstanceOfTableStakes = false;
                } else if (originalData != "--" && headerForCore == data && firstInstanceOfCore) {
                  data = "Core";
                  data = '<span class="popUp" data-modal-name="' + data + '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>' + data;
                  firstInstanceOfCore = false;
                } else if (originalData != "--" && headerForDiverge == data && firstInstanceOfDiverge) {
                  data = "Diverge";
                  data = '<span class="popUp" data-modal-name="' + data + '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>' + data;
                  firstInstanceOfDiverge = false;
                }
                return data;
              }
              return data;
            }
          },
          {
            targets: [1],
            className: "dt-left",
            width: 190,
            render: function(data, type, row, meta) {
              var parameters = meta.settings.oInit.columnDefs[meta.col].parameters;
              let originalData = data;
              data = data.length > 40 ? data.substr(0, 40) + "…" : data;
              let displayData = data;
              let includeToolTip = data.length > 40;

              if (type === "display") {
                let isEmotional = data.includes(",");
                let needType = isEmotional ? "emotional" : "functional";
                let fieldNumber = NeedsService.getNeedNumber(data, needType);
                if (fieldNumber == 0) {
                  fieldNumber = meta.row + 1;
                } else if (fieldNumber > 12) {
                  return data;
                }
                let needName = isEmotional ? "Emotional Need " : "Functional Need ";
                needName += fieldNumber;
                data = '<span class="popUp"  data-modal-name="' + needName + '"><i class="fa-duotone fa-circle-info" style="color:#2f5eb3; padding-right:5px;"></i></span>';
                if (includeToolTip) {
                  data += '<span data-toggle="tooltip" title="' + originalData + '">' + displayData + "</span>";
                } else {
                  data += displayData;
                }
              }
              return data;
            }
          },
          this.getNumericTableAttribute([2], "dt-right ", 50),
          this.getNumericTableAttribute([3], "dt-right ", 50),
          this.getNumericTableAttribute([4], "dt-right ", 50),
          this.getNumericTableAttribute(["_all"], "dt-right", 50)
        ]
      };
    } else if (source == "Profile") {
      return {
        columnDefsFunctional: [
          this.getOrderSequence(),
          { targets: [0], visible: false },
          this.getFunctionalNeedNameTableAttribute([1]),
          this.getNumericTableAttribute([2], "dt-right", 50),
          this.getNumericTableAttribute([3], "dt-right", 50),
          this.getNumericTableAttribute([4], "dt-right", 50),
          this.getNumericTableAttribute([-1], "dt-right", 50, 2),
          this.getPercentTableAttribute([-2], "dt-right", 50, 2),
          this.getNumericTableAttribute(["_all"], "dt-right", 50)
        ],
        columnDefsEmotional: [
          this.getOrderSequence(),
          { targets: [0], visible: false },
          this.getEmotionalNeedNameTableAttribute([1]),
          this.getNumericTableAttribute([2], "dt-right", 50),
          this.getNumericTableAttribute([3], "dt-right", 50),
          this.getNumericTableAttribute([4], "dt-right ", 50),
          this.getNumericTableAttribute([-1], "dt-right", 50, 2),
          this.getPercentTableAttribute([-2], "dt-right", 50, 2),

          this.getNumericTableAttribute(["_all"], "dt-right", 50)
        ]
      };
    } else if (source == "Match") {
      return {
        columnDefsFunctional: [
          this.getOrderSequence(),
          { targets: [0], visible: false },
          this.getFunctionalNeedNameTableAttribute([1]),
          this.getNumericTableAttribute([2], "dt-right", 50),
          this.getNumericTableAttribute([3], "dt-right", 50),
          this.getNumericTableAttribute([4], "dt-right", 50),
          this.getNumericTableAttribute(["_all"], "dt-right", 50)
        ],
        columnDefsEmotional: [
          this.getOrderSequence(),
          { targets: [0], visible: false },
          this.getEmotionalNeedNameTableAttribute([1]),
          this.getNumericTableAttribute([2], "dt-right", 50),
          this.getNumericTableAttribute([3], "dt-right", 50),
          this.getNumericTableAttribute([4], "dt-right", 50),
          this.getNumericTableAttribute(["_all"], "dt-right", 50)
        ]
      };
    } else if (source == "Trends") {
      return {
        columnDefs: [
          this.getOrderSequence(),
          this.getBaseTableAttribute([0], "dt-left", 70),
          this.getPercentTableAttribute([1], "dt-right chart-light-green", 75, 2),
          this.getNumericTableAttribute([2], "dt-right", 75),
          this.getNumericTableAttribute([3], "dt-right", 75),
          this.getNumericTableAttribute(["_all"], "dt-right", 75)
        ]
      };
    } else if (source == "Ranks") {
      return {
        columnDefs: [
          this.getOrderSequence(),
          this.getMoatNameTableAttribute([0]),
          this.getPercentTableAttribute([1], "dt-right", 50, 1),
          this.getPercentTableAttribute(["_all"], "dt-right", 50, 1)
        ]
      };
    } else if (source == "Moats") {
      return {
        columnDefs: [
          this.getOrderSequence(),
          this.getMainMoatNameTableAttribute([0]),
          this.getMoatNameTableAttribute([1]),
          this.getPercentTableAttribute([2], "dt-right", 50, 1),
          this.getPercentTableAttribute(["_all"], "dt-right", 50, 1)
        ]
      };
    } else if (source == "Moat Trends") {
      return {
        columnDefs: [
          this.getOrderSequence(),
          this.getBaseTableAttribute([0], "", 70),
          this.getPercentTableAttribute([1], "dt-right", 50, 1),
          this.getPercentTableAttribute(["_all"], "dt-right", 50, 1)
        ]
      };
    }
    return {};
  }
  getResponseMessageListTableAttributes() {
    return {
      id: "response-message-list-table",
      columns: [
        {
          title: "Id",
          data: "responseMessageLogId",
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-center");
          },
          className: "",
          name: "id",
          width: "4rem"
        },
        { title: "Title", data: "title", name: "title", width: "7rem" },
        { title: "Message", data: "message", name: "message", width: "10rem" },
        {
          title: "Completed",
          data: "completed",
          name: "completed",
          width: "6rem",
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-center");
          }
        },
        {
          title: "Status",
          data: "statusCode",
          name: "statusCode",
          width: "5rem",
          createdCell: function(td, cellData, rowData, row, col) {
            if (cellData != "200") {
              $(td).css("background-color", "red");
              $(td).css("color", "white");
            } else if (cellData == "200") {
              $(td).css("background-color", "green");
              $(td).css("color", "white");
            }
            $(td).addClass("text-center");
          }
        },
        {
          title: "Duration (ms)",
          data: "duration",
          name: "duration",
          width: "7rem",
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-center");
          }
        },
        { title: "Request Path", data: "requestPath", name: "requestPath", width: "12rem" },
        { title: "Request Parameters", data: "requestParameters", name: "requestParameters", width: "18rem" },
        { 
          title: "User Id", 
          data: "userId", 
          name: "userId", 
          width: "5rem",
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-left");
          }
         },
        {
          title: "Date Created",
          data: "dateCreated",
          type: "date",
          name: "dateCreated",
          width: "7rem",
          render: function(data, type, row) {
           return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          },
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-left");
          }
        },
        {
          title: "Date Completed",
          data: "dateCompleted",
          type: "date",
          name: "dateCompleted",
          width: "7rem",
          render: function(data, type, row) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          },
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-left");
          }
        }
      ]
    };
  }
  getApplicationErrorListTableAttributes() {
    return {
      id: "application-error-list-table",
      columns: [
        {
          title: "Id",
          data: "applicationErrorId",
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-center");
          },
          className: "",
          name: "id",
          width: "1rem"
        },
        { title: "Section", data: "applicationErrorType", name: "applicationErrorType", width: "3rem" },
        { title: "Location", data: "location", name: "location", width: "8rem" },
        { title: "Message", data: "errorMessage", name: "errorMessage", width: "20rem" },
        { title: "Client Name", data: "clientName", name: "clientName", width: "5rem" },
        {
          title: "Date Created",
          data: "dateCreated",
          type: "date",
          name: "dateCreated",
          width: "5rem",
          render: function(data, type, row) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          }
        }
      ]
    };
  }
  getPerformanceSummaryTableAttributes() {
    return {
      id: "performance-summary-list-table",
      columns: [
        { title: "Environment", data: "environment", name: "environment", width: "3rem" },
        { title: "Function", data: "functionName", name: "functionName", width: "8rem" },
        { title: "Minimum Duration (ms)", data: "minimumDuration", name: "minimumDuration", width: "3rem" },
        { title: "Maximum Duration (ms)", data: "maximumDuration", name: "minimumDuration", width: "3rem" },
        { title: "Average Duration (ms)", data: "average", name: "average", width: "3rem" },
        { title: "Number of Calls", data: "numberOfRecords", name: "numberOfRecords", width: "3rem" },
        {
          title: "Last Run Date",
          data: "lastRunDate",
          type: "date",
          name: "lastRunDate",
          width: "4rem",
          render: function(data, type, row) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          }
        }
      ]
    };
  }
  getCacheStatisticsTableAttributes() {
    return {
      id: "cache-statistics-list-table",
      columns: [
        { title: "Name", data: "name", name: "name", width: "9rem" },
        { title: "Hits", data: "hits", name: "hits", width: "3rem" },
        {
          title: "Hits %",
          data: "hitPercentage",
          name: "hitPercentage",
          width: "3rem",
          render(v) {
            if (v != null && !isNaN(v)) {
              return Number(v).toFixed(2) + "%";
            } else {
              return "--";
            }
          }
        },
        { title: "Misses", data: "misses", name: "misses", width: "4rem" },
        {
          title: "Miss %",
          data: "missPercentage",
          name: "missPercentage",
          width: "4rem",
          render(v) {
            if (v != null && !isNaN(v)) {
              return Number(v).toFixed(2) + "%";
            } else {
              return "--";
            }
          }
        },
        { title: "Gets", data: "gets", name: "gets", width: "3rem" },
        { title: "Puts", data: "puts", name: "puts", width: "3rem" },
        {
          title: "Average Get Time (ms)",
          data: "averageGetTime",
          name: "averageGetTime",
          width: "5rem",
          render(v) {
            if (v != null && !isNaN(v)) {
              return Number(v).toFixed(2);
            } else {
              return "--";
            }
          }
        },
        {
          title: "Average Put Time (ms)",
          data: "averagePutTime",
          name: "averagePutTime",
          width: "5rem",
          render(v) {
            if (v != null && !isNaN(v)) {
              return Number(v).toFixed(2);
            } else {
              return "--";
            }
          }
        },
        {
          title: "Average Remove Time (ms)",
          data: "averageRemoveTime",
          name: "averageRemoveTime",
          width: "5rem",
          render(v) {
            if (v != null && !isNaN(v)) {
              return Number(v).toFixed(2);
            } else {
              return "--";
            }
          }
        },
        {
          title: "",
          data: "name",
          name: "name",
          width: "6rem",
          render: function(data, type, row, meta) {
            if (type === "display") {
              data = '<button type="button" class="btn btn-clear-cache btn-primary ml-2">Clear Cache</button>';
              //data = '<a href="/clear-cache-by-name?name=' + encodeURIComponent(data.name) + '">Clear Cache</a>';
            }
            return data;
          }
        }
      ]
    };
  }
  getJobSummaryTableAttributes() {
    return {
      id: "job-summary-table",
      columns: [
        { title: "Name", data: "name", name: "name", width: "8rem" },
        { title: "Frequency", data: "frequency", name: "frequency", width: "8rem" },
        { title: "Active", data: "active", name: "active", width: "4rem" },
        {
          title: "Last Run Date",
          data: "dateLastRun",
          type: "date",
          name: "dateLastRun",
          width: "4rem",
          render: function(data, type, row) {
            if (data == null) {
              return "--";
            }
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          }
        },
        {
          title: "Next Run Date",
          data: "dateNextRun",
          type: "date",
          name: "dateNextRun",
          width: "4rem",
          render: function(data, type, row) {
            if (data == null) {
              return "--";
            }
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          }
        },
        {
          title: "Created Date",
          data: "dateCreated",
          type: "date",
          name: "dateCreated",
          width: "4rem",
          render: function(data, type, row) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          }
        },
        {
          title: "Updated Date",
          data: "dateModified",
          type: "date",
          name: "dateModified",
          width: "4rem",
          render: function(data, type, row) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          }
        }
      ]
    };
  }
  getJobExecutionLogTableAttributes() {
    return {
      id: "response-message-list-table",
      columns: [
        {
          title: "Id",
          data: "id",
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-center");
          },
          className: "",
          name: "id",
          width: "4rem"
        },
        { title: "Job Name", data: "jobName", name: "jobName", width: "6rem" },
        { title: "Message", data: "message", name: "message", width: "9rem" },
        {
          title: "Completed",
          data: "completed",
          name: "completed",
          width: "5rem",
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-center");
          }
        },
        {
          title: "Status",
          data: "statusCode",
          name: "statusCode",
          width: "4rem",
          createdCell: function(td, cellData, rowData, row, col) {
            if (cellData != "200") {
              $(td).css("background-color", "red");
              $(td).css("color", "white");
            } else if (cellData == "200") {
              $(td).css("background-color", "green");
              $(td).css("color", "white");
            }
            $(td).addClass("text-center");
          }
        },
        {
          title: "Duration (ms)",
          data: "duration",
          name: "duration",
          width: "4rem",
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-center");
          }
        },
        {
          title: "Date Created",
          data: "dateCreated",
          type: "date",
          name: "dateCreated",
          width: "8rem",
          render: function(data, type, row) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          }
        },
        {
          title: "Date Completed",
          data: "dateCompleted",
          type: "date",
          name: "dateCompleted",
          width: "8rem",
          render: function(data, type, row) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          }
        }
      ]
    };
  }
  getCountsByLocationTableAttributes() {
    return  [
        { data: "count", name: "ipAddress", width: "1rem", 
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-center");
          },
        },
        {  data: "city", name: "city", width: "15rem", 
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-center");
          },
        },
        {  data: "regionCode", name: "regionCode", width: "15rem", 
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-center");
          },
       },
      {  data: "displayCountryCode", name: "displayCountryCode", width: "15rem",
        createdCell: function(td, cellData, rowData, row, col) {
          $(td).addClass("text-center");
        },
       },
      ];
  }  
  getImportTypeAttributes() {
    return {
      id: "import-type-table",
      columns: [
        { title: "Import Type", data: "name", name: "name", width: "8rem" },
        { title: "API Call", data: "filepath", name: "filepath", width: "10rem" },
        { title: "Current Function Location", data: "description", name: "description", width: "10rem" },
        {
          title: "Created Date",
          data: "dateCreated",
          type: "date",
          name: "dateCreated",
          width: "4rem",
          render: function(data, type, row) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          }
        },
        {
          title: "Updated Date",
          data: "dateModified",
          type: "date",
          name: "dateModified",
          width: "4rem",
          render: function(data, type, row) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          }
        }
      ]
    };
  } 
  getImportLogTableAttributes() {
    return {
      id: "import-table",
      columns: [
        {
          title: "Id",
          data: "id",
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-center");
          },
          className: "",
          name: "id",
          width: "4rem"
        },
        { title: "Import Type Name", data: "importTypeName", name: "importTypeName", width: "8rem" },
        { title: "File Name", data: "filename", name: "filename", width: "9rem" },
        {
          title: "Status",
          data: "status",
          name: "status",
          width: "4rem",
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-center");
          }
        },
        {
          title: "Completed",
          data: "activeFlag",
          name: "activeFlag",
          width: "6rem",
          createdCell: function(td, cellData, rowData, row, col) {
            $(td).addClass("text-center");
          }
        },
        {
          title: "Start Date",
          data: "startDate",
          type: "date",
          name: "startDate",
          width: "6rem",
          render: function(data, type, row) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          }
        },
        {
          title: "End Date",
          data: "endDate",
          type: "date",
          name: "endDate",
          width: "6rem",
          render: function(data, type, row) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          }
        },
        
        {
          title: "Date Created",
          data: "dateCreated",
          type: "date",
          name: "dateCreated",
          width: "6rem",
          render: function(data, type, row) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          }
        },
        {
          title: "Date Completed",
          data: "dateCompleted",
          type: "date",
          name: "dateCompleted",
          width: "6rem",
          render: function(data, type, row) {
            return moment(data, "YYYY-MM-DDTHH:mm:ss.SSSZ").tz("America/Toronto").format("MM/DD/YYYY hh:mm:ss A");
          }
        }
      ]
    };
  }
}
export default new TableService();

<template>
    <div class="col-12 no-gutters container mt-3">
        <div class="e-chart-container">
            <div class="title-and-chart">
                <div :id="eChartId" class="e-chart">
                    <chart :options="chart.options"></chart>
                </div>
                <div :id="eChartMiniId">
                    <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
                </div>             
            </div>
        </div>
    </div>
</template>

<script>
import ScatterService from "../../services/echarts/scatter.service";
import ReportService from "../../services/report.service";
import TrendsService from "../../services/echarts/trends.service";
import EChartsMixins from "../../mixins/EChartsMixins";
import BarVerticalService from "../../services/echarts/bar.vertical.service";

import $, { isEmptyObject } from "jquery";

export default {
  name: "TrendsChart",
  mixins: [EChartsMixins],
  components: {},
  data() {
    return {
        charts: [],
        legend : [],
        colors: [],
        vAxis: [],
        moatSelected: false,
        addWrapper: false,
    };
  },
  props: {},
  computed: {},
  mounted() {},
  created() {},
  methods: {
    //https://codepen.io/plainheart/pen/bGgmGPj    
    setDataForTrends: function(needsData,chart1,chart2 = {},diplayType){
        this.setDataForBaseChart(needsData,chart1,chart2,diplayType);
        let chart1Data = {};
        let chart2Data = {};        
        if (typeof needsData != "undefined" && !$.isEmptyObject(needsData)) {
            if (typeof needsData.chart1 != "undefined" && needsData.chart1.data.length > 0){
                chart1Data = JSON.parse(JSON.stringify(needsData.chart1));
                if (typeof needsData.chart1.containerId != "undefined"){
                    chart1.containerId = needsData.chart1.containerId;
                }
            }                
            if (typeof needsData.chart2 != "undefined" && needsData.chart2.data.length > 0){
                chart2Data = JSON.parse(JSON.stringify(needsData.chart2));
                if (typeof needsData.chart2.containerId != "undefined"){
                    chart2.containerId = needsData.chart2.containerId;
                }
            }           
            this.vAxis = [];
            if (typeof needsData != "undefined" && typeof needsData.vAxis != "undefined") {
                this.vAxis = JSON.parse(JSON.stringify(needsData.vAxis));
            }
        }
        let displayedLegend = JSON.parse(JSON.stringify(this.legend.normal));
        for (let legendIndex = 0; legendIndex < displayedLegend.length; legendIndex++) {
            let legend = displayedLegend[legendIndex];
            let legendName = legend.name;
            if (legendIndex == 0){
                chart1.series.push(TrendsService.getMetricSeries(legendName,0,0,1,"rgb(215, 228, 189)",false,true)); 
            } else if (legendIndex == 1){
                let series = TrendsService.getSeries(legendName,0,0,0,TrendsService.getBaseConfig("line"),this.moatSelected);
                if (legendName == "Importance" || legendName == "Expectations"){
                    series.symbol = "none";
                } 
                chart1.series.push(series);
            } else if (legendIndex == 2){
                let series = TrendsService.getSeries(legendName,0,0,0,TrendsService.getCompanyConfig(),this.moatSelected);
                series.symbol = "circle";
                series.symbolSize = 10;
                chart1.series.push(series);
            } else {
                let series = TrendsService.getSeries(legendName,0,0,0,TrendsService.getBaseConfig(),this.moatSelected);
                series.symbol = "circle";
                series.symbolSize = 10;
                chart1.series.push(series);
            }
        }
        chart1 = this.setChart(chart1Data, chart1);
        this.charts.push(chart1);
        if (!$.isEmptyObject(chart2Data)){
            chart2 = this.setChart(chart2Data, chart2);
            this.charts.push(chart2);
        }
    },
    setChart: function(needsData, chart){
        let displayNumberOfRespondents = this.eChartCriteria.displayNumberOfResponses;
        chart = this.clearChart(chart);
        let xAxisData = [];
        for (let needsIndex = 0; needsIndex < needsData.data.length; needsIndex++) {
            let companyProperties = needsData.data[needsIndex];
            if (companyProperties.length >= 3) {
                let seriesIndex = -1;
                for (let propertyIndex = 0; propertyIndex < companyProperties.length; propertyIndex++) {
                    let property = companyProperties[propertyIndex];
                    let dataArray = [];
                    if (typeof property != 'undefined' && property != null ) {
                        if (propertyIndex == 0){
                            if (displayNumberOfRespondents && typeof this.legend.respondents != "undefined"){
                                xAxisData.push(property);    
                            } else {
                                property = property.split('(')[0];
                                xAxisData.push(property);
                            }
                        } else if (propertyIndex == 1){
                            let formatter = typeof this.vAxis[0].configuration.formatter != "undefined" ? this.vAxis[0].configuration.formatter : '#';
                            let propertyData = {value: property, formatter: formatter};
                            chart.series[seriesIndex].data.push(propertyData);
                        } else if (propertyIndex == 2){
                            let formatter = typeof this.vAxis[1] != "undefined" && typeof this.vAxis[1].configuration.formatter != "undefined" ? this.vAxis[1].configuration.formatter : '#';
                            let propertyData = {value: property, formatter: formatter};
                            chart.series[seriesIndex].data.push(propertyData);
                        } else if (seriesIndex < chart.series.length){
                            let formatter =  typeof this.vAxis[1] != "undefined" && typeof this.vAxis[1].configuration.formatter != "undefined" ? this.vAxis[1].configuration.formatter : '#';
                            let propertyData = {value: property, formatter: formatter};
                            chart.series[seriesIndex].data.push(propertyData);
                        }
                        seriesIndex++;
                    }
                }
            }            
        }
        chart.xAxis.data = JSON.parse(JSON.stringify(xAxisData));
        return chart;
    },
    getChartOptions: function(){
        let options = {};
        let showToolbox =  !(ReportService.getReportAutoChartGenerateStatus() == "generate");
        let colorPaletteId = this.eChartCriteria.colorPaletteId;
        let showLegend = this.eChartCriteria.showLegend;
        let showLabel = false;
        let angleText = true;
        let isMultipleWaves = true;
        let displayNumberOfRespondents = this.eChartCriteria.displayNumberOfResponses;
/* Start of function that should go into helper service */
        let xAxis = [];
        let seriesData = [];
        for (let i = 0; i < this.charts.length; i++) {
            let chart = this.charts[i];
            for (let i = 0; i < chart.series.length; i++) {
                let series = chart.series[i];
                seriesData.push(series);
            }
            $.extend(true, chart.xAxis.axisLabel, TrendsService.getDefaultAxisTextStyleForAnalyticChart());
            if (angleText == true){           
                $.extend(true, chart.xAxis.axisLabel, {rotate: 25,hideOverlap: true,interval: 0});
            } else {
                let defaultAxisLabel = {
                    overflow: "break",
                    interval: 0,
                    width: 40,
                    ellipsis: '...',        
                    rotate: 0,
                    hideOverlap: false,
                };
                $.extend(true, chart.xAxis.axisLabel, defaultAxisLabel);
            }
            let breakPointList = [];
            let numberOfWaves = chart.xAxis.data.length;
            if (numberOfWaves > 4){
                let numberOfBreakPoints = Math.floor(numberOfWaves / 4);
                for (let i = 1; i <= numberOfBreakPoints; i++) {
                    breakPointList.push(i * 4);
                }
                let splitLine = {
                    show: true,
                    interval: function(index, value) {
                        return breakPointList.includes(index);              
                    },             
                };
                $.extend(true, chart.xAxis.splitLine, splitLine);
            }
            xAxis.push(chart.xAxis);
            xAxis.push(chart.header);
        }
/* End of function that should go into helper service */
        ScatterService.setShowSnap(this.isSavedCard);
        ScatterService.setShowToolbox(showToolbox);
        ScatterService.setColorPaletteId(colorPaletteId);
        ScatterService.setShowLabel(showLabel);
        ScatterService.setShowLegend(showLegend);
        ScatterService.setIsMultiWave(isMultipleWaves);
        ScatterService.setChartType(this.eChartCriteria.eChartType);
        let legendData = [];
        let legendNames = this.legend.normal.map(entry => entry.name);
        for (let i = 0; i < legendNames.length; i++) {
            if (legendNames[i] == "Importance" || legendNames[i] == "Expectations"){
                let legend = {name: legendNames[i], icon: "rec"};
                legendData.push(legend);
            } else {
                legendData.push(legendNames[i]);
            }
        }
        ScatterService.setLegendData(legendData);
        ScatterService.setSeriesData(seriesData);
        let yAxis = TrendsService.getYAxis(this.vAxis,this.displayType);
        let metricData = seriesData[0].data.map(entry => entry.value);
        let valuesSecondary = TrendsService.findMinMax(metricData);
        let min = valuesSecondary.min;
        let max = valuesSecondary.max;
        // Metrics Axis
        yAxis[1].min = min;
        yAxis[1].max = max;
        //Special log for NPS
        if (legendData.includes("NPS")){
            yAxis[0].min = null;
            yAxis[0].max = null;
        }
        ScatterService.setYAxis(yAxis); 
        ScatterService.setXAxis(xAxis);
        options = ScatterService.getOptions(this,this.modalId, this.inputReportTypeName);
        if (!$.isEmptyObject(options)) { 
           // options.tooltip.trigger =  'item',
            options.tooltip.backgroundColor = "#ffffff";
            options.tooltip.formatter =  TrendsService.toolTipForTrendsChart;            
            //override normal options
            options.color = this.colors;
            options.series[1].label.color = "#2F5EB3";
            for (let i = 0; i < options.grid.length; i++) {
                options.grid[i].top = 40;
            }
            $.extend(true, options.toolbox, TrendsService.getToolboxForAnalyticsChart());
            options.series.push(BarVerticalService.getMarkLine(false,0));            
        }
        return options;           
    },
    processChart: function(runChart = false,displayType,needsData,eChartCriteria,moatSelected = false){
        if (runChart == true) {
            this.displayType = displayType;
            this.moatSelected = moatSelected;
            if (typeof eChartCriteria != "undefined") {
                this.eChartCriteria = JSON.parse(JSON.stringify(eChartCriteria));
            }
            let driverList = [];
            if (typeof needsData != "undefined" && typeof needsData.driverList != "undefined") {
                driverList = JSON.parse(JSON.stringify(needsData.driverList));
            }

            // get the splits for charts
            let breakPointList = [];
            if (typeof needsData != "undefined" && typeof needsData.breakPointList != "undefined") {
                breakPointList = JSON.parse(JSON.stringify(needsData.breakPointList));
            }
            let needsList = [];
            if (typeof needsData != "undefined" && typeof needsData.needsList != "undefined") {
                needsList = JSON.parse(JSON.stringify(needsData.needsList));
                needsList = needsList.filter(function (need) {
                    return need.needName != "";
                });
            }
            if (displayType == "trends" || displayType == "moat-trends"){
                let chart1 = {
                    containerId: "",
                    header: TrendsService.getEmptyXAxis(),
                    series: [],
                    xAxis: TrendsService.getXAxisForNeeds(displayType,breakPointList),                
                };
                ScatterService.setGrid(TrendsService.getGridForSingleChart());
                this.setDataForTrends(needsData,chart1,{},displayType);
                this.initChart();
            } 
            if (typeof needsData != "undefined" && typeof eChartCriteria != "undefined" && this.charts.length > 0) {
                this.$emit('save-criteria',true);
                this.$emit('save-table');

            }
        }
    },
  },
  watch: {},
};
</script>

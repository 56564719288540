<template>
  <div class="panel-admin-body">
    <div class="d-flex title">
      <div class="pb-1">
        <modal-link modal-id="How to Use" :content-list="pageContent"></modal-link>
        {{ title }}
        <smart-center-link page="Predictive" location="Header" />
      </div>
      <div class="ml-auto">
        <span class="wizard-icon">
          <!--
          <font-awesome-icon  v-tooltip="tooltipConfigShow" :icon="['fas', 'hat-wizard']" @click="$root.toggleWizardContent()"/>
          -->
          <i class="fa-duotone fa-hat-wizard" aria-hidden="true" @click="$root.toggleWizardContent()"></i>
        </span>
        <i v-show="this.$root.getShowMenuIcon() && this.$root.getShowHeaderMenu()" class="fa fa-window-minimize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
        <i v-show="this.$root.getShowMenuIcon() && !this.$root.getShowHeaderMenu()" class="fa fa-window-maximize pl-1 pr-1" aria-hidden="true" @click="toggleHeaderMenu()"></i>
      </div>
    </div>
    <chart-menu></chart-menu>
    <div v-show="$root.getShowWizardContent()" class="wizard-content">
      <div class="wizard-content-column-two-entries wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">1</div>
          <div class="wizard-text">
            Predictive Analytics shows what areas of your 
            performance with customers are contributing 
            the most to growth. Select the Growth Metric 
            you want to analyze against your performance.          
          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">2</div>
          <div class="wizard-text">
            Click the Start and End Periods to run the 
            analysis. At least three periods of data are 
            necessary to run analysis. Period End identifies 
            the last survey wave for data analysis.
          </div>
        </div>
      </div>
      <div class="wizard-content-column wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">3</div>  
          <div class="wizard-text">
            Predictive Analytics uses correlation (or 
            bivariate) analysis to look for patterns in 
            customer perception of performance and the 
            growth metric selected. Relationship Strength 
            describes the magnitude of the bond, with 
            darker greens indicating much closer 
            movement between the two. Correlation does 
            not equal causation until Leadership applies it.
          </div>      
        </div>
      </div>
      <div class="wizard-content-column-two-entries wizard-border col-4">
        <div class="w-100 d-flex">
          <div class="circle">4</div>
          <div class="wizard-text">
            Segments are created through your survey questions. Click any 
            segment criteria to select which respondents to include in your 
            Predictive Analytics. Be sure to load the corresponding Growth 
            Metric to any segments to ensure valid analysis. Selecting no 
            criteria includes all respondents for that question. Segments for 
            NPS, Location and Word are included as defaults.
          </div>
        </div>
        <div class="w-100 d-flex pt-2">
          <div class="circle">5</div>  
          <div class="wizard-text">
            Click Generate to build your Analytics table. Click Reset to start 
            the build process from scratch.
          </div>      
        </div>
      </div>
    </div>
    <div class="predictive-analytics">
<!-- Chart Criteria -- Start -->      
      <div v-if="!display" class="container pt-2">
        <chart-criteria-skeleton />
      </div>
      <div v-if="display" class="form-row">
        <div class="col-4 mt-2 chart-criteria-border">
          <div class="form-group">
          <label for="metricGroupId" class="mr-2 mt-2">Primary Axis</label>
          <div class="w-75 shadow">
              <select id="metricGroupId" v-model="metricGroupId" name="metricGroupId" class="custom-select" @change="clearMetricsTable($event,1)">
                <option
                  v-for="(option, idxMetricGroupIdOptions) in this.metric_options"
                  v-bind:value="option.value"
                  v-bind:key="idxMetricGroupIdOptions"
                  v-bind:selected="metricGroupId == option.value"
                >
                  {{ option.name }}
                </option>
              </select>              
            </div>
          </div>
          <div class="form-group">
          <label for="secondaryMetricGroupId" class="mr-2 mt-2">Secondary Axis</label>
          <div class="w-75 shadow">
              <select id="secondaryMetricGroupId" v-model="secondaryMetricGroupId" name="secondaryMetricGroupId" class="custom-select" @change="clearMetricsTable($event,2)">
                <option value="-1">None</option>
                <option
                  v-for="(option, idxMetricGroupIdOptions) in this.secondary_metric_options"
                  v-bind:value="option.value"
                  v-bind:key="idxMetricGroupIdOptions"
                  v-bind:selected="secondaryMetricGroupId == option.value"
                >
                  {{ option.name }}
                </option>
              </select>              
            </div>
          </div>
          
          <div class="form-group pb-2">
          <label for="additionalSecondaryMetricGroupIds" class="mr-2 mt-2">Secondary Axis - Additional Metrics</label>
          <div class="w-75 shadow">
            <multiselect
              ref="additionalSecondaryMetric"
              :disabled="disableSecondaryAxisAdditionalMetricDropdown"
              v-model="selectedAdditionalSecondaryMetrics"
              :options="additional_secondary_metric_options"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :max-height="1250"
              select-label=""
              deselect-label=""
              selected-label=""
              placeholder="None"
              label="name"
              name="additional_secondary_metrics"
              track-by="value"  
              @select="addAdditionalSecondaryMetric"
              @remove="removeAdditionalSecondaryMetric" 
              :preselect-first="false"
            >
              <template #option="props">
                <span class="checkbox-label" @click.self="select(props.option)">
                  <input name="additional_secondary_metrics" class="mr-1" type="checkbox" v-model="props.option.checked" @focus.prevent />
                  {{ props.option.name }}
                </span>
              </template>
            </multiselect>
          </div>
          </div>
          
          <div class="row mr-2">
            <div class="col form-group">
              <label for="startPeriod" class="control-label pt-1 my-auto">Period Starts</label>
              <div class="w-100 shadow">
                <v-select
                  id="periodId"
                  name="periodId"
                  :options="period_options"
                  :reduce="period_option => period_option.clientTimePeriodId"
                  label="chartDisplayName"
                  :clearable="false"
                  v-model="startPeriod"
                ></v-select>
              </div>
            </div>
            <div class="col form-group">
              <label for="endPeriod" class="control-label pt-1 my-auto">Period End</label>
              <div class="w-100 shadow">
                <v-select
                  id="periodId"
                  name="periodId"
                  :options="period_options"
                  :reduce="period_option => period_option.clientTimePeriodId"
                  label="chartDisplayName"
                  :clearable="false"
                  v-model="endPeriod"
                ></v-select>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="surveyGroup" class="control-label pt-1 my-auto">Group</label>
            <div class="">
              <select
                id="surveyGroup"
                name="surveyGroup"
                class="custom-select shadow"
                :disabled="disableChartCriteriaWidgets"
                v-on:change="saveValue($event, 'Survey Group', 'survey_group', true);setUseEqualWeight();"
                v-model="surveyGroup"
              >
                <option value="">None</option>
                <option v-for="(option, idxSurveyGroupOptions) in survey_group_options" v-bind:value="option.value" v-bind:key="idxSurveyGroupOptions">
                  {{ option.name }}
                </option>
              </select>
            </div>
            <div class="form-group pt-2">
              <input 
                id="showPublicGroups" 
                name="showPublicGroups" 
                type="checkbox" 
                true-value="Y" 
                false-value="N" 
                v-model="showPublicGroups" 
                @click="saveCheckboxValue($event, 'Show Public Groups', 'show_public_groups', true)"
                />
              <span class="ml-1 mr-1">Show Public Groups</span>
              <modal-link modal-id="Show Public Groups" :content-list="pageContent"></modal-link>
            </div>    
            <div class="form-group">
                  <input
                    id="includePartialResponses"
                    name="includePartialResponses"
                    type="checkbox"
                    true-value="Y"
                    false-value="N"
                    v-model="includePartialResponses"
                    @click="saveCheckboxValue($event, 'Include Partial Responses', 'include_partial_responses', true)"
                  />
                  <span class="ml-1 mr-1">Include Partial Responses</span>
                  <modal-link modal-id="Include Partial Responses" :content-list="pageContent"></modal-link>
                </div>
            <div class="form-group pt-2">
                  <input 
                    id="useEqualWeight" 
                    name="useEqualWeight" 
                    type="checkbox" 
                    true-value="Y" 
                    false-value="N"
                    :disabled="disableEqualWeight" 
                    v-model="useEqualWeight" 
                    @click="saveCheckboxValue($event, 'Calculate Surveys Equal-Weighted', 'use_equal_weight', true)"
                    />
                  <span class="ml-1 mr-1">Calculate Surveys Equal-Weighted</span>
                  <modal-link modal-id="Calculate Surveys Equal-Weighted" :content-list="pageContent"></modal-link>
              </div>                               
          </div>
        </div>
        <div class="col-3 mt-2 chart-criteria-border">
          <div class="d-flex m-1">
            <label for="relationShipStrength" class="mr-2 mt-2">Relationship Strength</label>
          </div>
          <div class="d-flex m-1">
            <div class="very-strong-correlation-square border"></div>
            <div class="pl-2 align-self-center">Very Strong Correlation (0.8 to 1.0)</div>
          </div>
          <div class="d-flex m-1">
            <div class="strong-correlation-square border"></div>
            <div class="pl-2 align-self-center">Strong Correlation (0.5 to 0.79)</div>
          </div>
          <div class="d-flex m-1">
            <div class="mild-correlation-square border"></div>
            <div class="pl-2 align-self-center">Mild Correlation (0.3 to 0.49)</div>
          </div>
          <div class="d-flex m-1">
            <div class="no-correlation-square border"></div>
            <div class="pl-2 align-self-center">No Correlation (Below 0.3 or negative)</div>
          </div>
        </div>
        <div class="col-5 mt-2">
          <div v-if="display" class="segments row">
            <div v-for="(chartCriteria, idxSegments) in segments" v-bind:key="idxSegments" class="col-lg-4 col-sm-12">
              <chart-criteria-option
                placeholder="Select"
                option-type="multiselect"
                label-class="control-label pt-1 my-auto pr-1"
                v-bind:option-object="chartCriteria"
                :resetOption="resetOption"
                :chartTitle="chart.title"
                :surveyName="survey.name"
                :content-list="pageContent"
                @add="saveResultValue"
              ></chart-criteria-option>
            </div>
            <div class="d-none col-lg-4 col-sm-12">
              <div class="form-group">
                <label for="survey_location" class="control-label pt-1 pr-1 my-auto">Location</label>
                <modal-link modal-id="Location" :content-list="pageContent"></modal-link>
                <a class="mr-1" href="#" @click="showPopup">
                  (Click to View Counts)                        
                </a>
                <treeselect
                  v-model="surveyLocation"
                  :multiple="true"
                  :alwaysOpen="false"
                  :show-count="false"
                  :default-expand-level="1"
                  :options="survey_location_options"
                  :append-to-body="true"
                  :required="false"
                  :clearable="false"
                  :maxHeight="400"
                  placeholder="Select"
                />
              </div>
            </div>
            <div class="col-lg-4 col-sm-12">
              <div class="form-group">
                <label for="word" class="control-label pt-1 pr-1 my-auto">Word</label>
                  <Treeselect
                        :model-value="words"
                        @update:modelValue="onInputWords"
                        :is-independent-nodes="true"
                        :is-single-select="false"
                        :alwaysOpen="false"
                        :show-count="false"
                        :default-expand-level="1"
                        :options="word_options"
                        :append-to-body="true"
                        :required="false"
                        :clearable="false"
                        :maxHeight="400"
                        placeholder="Select"
                      />
              </div>
            </div>                  

          </div>
        </div>
      </div>
<!-- Chart Criteria -- End -->            
      <div v-if="display" class="row no-gutters">
        <div class="btn-group-horizontal pb-2" role="group">
          <button type="submit" class="btn btn-primary mr-3" @click="getDetailedPredictiveAnalytics" :disabled="disableGenerate">
            <span>Generate</span>
            <span v-show="this.$root.getLoading()" class="spinner-border spinner-border-sm"></span>
          </button>
          <button type="submit" class="btn btn-primary" @click="resetChartCriteria">Reset</button>
        </div>
      </div>
      <div v-show="disableGenerate" class="row no-gutters pt-2 pb-2">
        <div>
          You cannot generate a profile unless you create at least one metric. Click here
          <router-link :to="{ name: 'metric-list' }">Respondent->Metrics</router-link>
          to add a metric
        </div>
      </div>
      <chart-criteria-results
        ref="chartCriteriaResults"
        :is-predictive-analytics="true"
        v-bind:criteria-results-list="criteriaResultsList"
        v-bind:criteria-results-company-list="criteriaResultsCompanyList"
      ></chart-criteria-results>
      <div v-show="getDisplayView()" class="row mt-3 mb-3">
        <div id="buttonContainer" class="col-lg-12">
          <button id="save_chart" class="btn btn-primary ml-2" type="button" @click="$modal.show('save-chart-confirmation')" :disabled="disableSaveAsCardButton">Save as Card</button>
        </div>
      </div>
      <div id="predictive-analytics-chart" class="col-12 chart-data" v-show="displayChartOnly && getDisplayView()">
        <profile-chart-header
            :title="chartTitle"
            :display="false"
            :input-display-number-of-responses="eChartCriteria.displayNumberOfResponses"
            @set-display-number-of-responses="setDisplayNumberOfResponses"
        />
        <div class="row">
          <div class="col-2">
              <predictive-analytics-chart-buttons ref="chartButtons" 
                  :input-show-labels="eChartCriteria.showLabels"
                  @set-show-labels="setShowLabels"
              />
          </div> 
          <predictive-analytics-chart ref="eChart"
              :e-chart-mini-id="'predictive-analytics-e-chart-mini'"
              :e-chart-id="'predictive-analytics-e-chart'"
              :input-report-type-name="'Chart - Impact'"
              :modal-id="'predictive-analytics-chart'"
              :is-saved-card="this.clientChart.clientChartId != 0" 
              :input-client-chart="this.clientChart"
              @save-table="saveProfileDataTable"
              @save-criteria="buildClientChartForPredictiveAnalytics"/>        
        </div>  
      </div>  
      <div v-show="isGenerateReportRunning() || (!displayChartOnly && getDisplayView())" id="analytics-contributions-container" class="predictive-analytics-echart">
        <div v-show="isGenerateReportRunning() || (!displayChartOnly && getDisplayView())" class="contribution  w-100"
            v-for="(contributionChart, idxContributionChart) in this.contributionChartList"
            v-bind:key="idxContributionChart">
                    <predictive-analytics-contribution-chart 
                      :is-saved-card="clientChart.clientChartId != 0" 
                      :contribution-chart="contributionChart" 
                      v-if="getDisplayView()"/>

        </div>
      </div>
      <div v-show="!displayChartOnly && getDisplayView()" id="analytics-title-and-chart" class="title-and-chart d-flex pt-3">
        <div v-show="!displayChartOnly && getDisplayView()" id="analytics-data-container" :class="isInsiderUser ? 'hide-table-data' : ''">
          <div v-show="isInsiderUser" class="overlay">
            <img class="overlay-inner" v-show="isInsiderUser" src="../../assets/img/overlap.png"  style="width: 250px"/>
          </div>
          <table v-if="isGenerateReportRunning() || (!displayChartOnly && getDisplayView())" :id="predictiveAnalyticsTable.id" style="display: none" class="table display wrap row-border table-hover" cellspacing="0" width="auto">
            <thead>
              <tr>
                <th
                  v-for="(baseDataHeader, idxBaseDataHeader) in this.predictiveAnalyticsData.tableHeader"
                  v-bind:key="idxBaseDataHeader"
                  v-bind:class="[baseDataHeader.hasOwnProperty('headerClass') ? baseDataHeader.headerClass : 'predictive-column']"
                >
                  <span v-html="baseDataHeader.data"></span>
                </th>
              </tr>
            </thead>
            <tbody></tbody>
          </table>
        </div>
      </div>
      <div v-show="getDisplayView()" id="metric-trends-title-and-chart" class="title-and-chart d-flex pt-3">
          <div v-show="getDisplayView()" id="metric-trends-data-container" :class="isInsiderUser ? 'hide-table-data' : ''">
            <div v-show="isInsiderUser" class="overlay">
              <img class="overlay-inner" v-show="isInsiderUser" src="../../assets/img/overlap.png"  style="width: 250px"/>
            </div>
            <table v-if="getDisplayView()" :id="metricsTable.id" style="display: none" class="table display nowrap row-border table-hover" cellspacing="0" width="auto" height="80px">
              <thead>
                <tr>
                  <th
                    v-for="(baseDataHeader, idxBaseDataHeader) in this.metricsData.tableHeader"
                    v-bind:key="idxBaseDataHeader"
                    v-bind:class="[baseDataHeader.hasOwnProperty('headerClass') ? baseDataHeader.headerClass : 'predictive-column']"
                  >
                    <span>{{ baseDataHeader.data }}</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(returnMetric, idxReturnMetrics) in this.returnMetricTableRows"
                  v-bind:key="idxReturnMetrics"
                >
                  <td
                      v-for="(sales, idxSales) in returnMetric"
                      v-bind:key="idxSales"
                      v-bind:class="[sales.hasOwnProperty('dataClass') ? sales.dataClass : 'predictive-column']"
                    >
                      <span>{{ sales.data }}</span>
                    </td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
      <div v-show="!displayChartOnly && getDisplayView()" id="profile-trends-title-and-chart" class="title-and-chart d-flex pt-3">
        <div v-show="!displayChartOnly && getDisplayView()" class="w-100 mt-5">
          <div v-show="!displayChartOnly && getDisplayView()" id="profile-trends-data-container" :class="isInsiderUser ? 'hide-table-data' : ''">
            <div v-show="isInsiderUser" class="overlay">
              <img class="overlay-inner" v-show="isInsiderUser" src="../../assets/img/overlap.png"  style="width: 250px"/>
            </div>
            <table v-if="isGenerateReportRunning() || (!displayChartOnly && getDisplayView())" :id="needsTable.id" style="display: none" class="table display wrap row-border table-hover" cellspacing="0" width="auto">
              <thead>
                <tr>
                  <th></th>
                  <th
                    v-for="(tableHeader, idxTableHeader) in this.needsData.tableHeader"
                    v-bind:key="idxTableHeader"
                    v-bind:class="[tableHeader.hasOwnProperty('headerClass') ? tableHeader.headerClass : 'predictive-column']"
                  >
                    <span>
                      {{ tableHeader.data }}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, idxRows) in this.needsData.rowsMap.rows" v-bind:key="idxRows">
                  <td v-if="idxRows == 0">1</td>
                  <td v-if="idxRows != 0">0</td>
                  <td v-for="(needRow, idxNeedsRow) in row" v-bind:key="idxNeedsRow" v-bind:class="[needRow.hasOwnProperty('dataClass') ? needRow.dataClass : 'predictive-column']">
                    <span>{{ needRow.data }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <vue-modal ref="save-chart-confirmation" name="save-chart-confirmation" :width="350" :height="200">
        <save-chart button-label="Save as Card" :chart-title="this.chart.title" :initial-client-chart="this.clientChart" @save="updateClientCharts" @closeModal="closeModal('save-chart-confirmation')"></save-chart>
      </vue-modal>
    </div>
    
    <insights
      ref="insightsChartImpact"
      :modal-name="'predictive-analytics-chart'"
      :report-page-type-name="'Chart - Impact'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="saveClientChart"
    />

    <insights
      ref="insightsMetricsTable"
      :modal-name="'metrics-table'"
      :report-page-type-name="'Metric Trends'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="saveClientChart"
    />
    <insights
      ref="insightsPredictiveAnalyticsTable"
      :modal-name="'predictive-analytics-table'"
      :report-page-type-name="'Predictive Analytics'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="saveClientChart"
    />
    <insights
      ref="insightsProfileTable"
      :modal-name="'needs-table'"
      :report-page-type-name="'Profile Trends'"
      :entry-page-content="this.pageContentForReport"
      @saveReportPage="updateReportPages"
      @saveAllReportPages="saveAllReportPages"
      @saveClientChart="saveClientChart"
    />
    <counts-by-location ref="countsByLocation" :period="String(endPeriod)"/>
  </div>
</template>
<script>
import $ from "jquery";
import { reactive, toRaw } from 'vue'
import ChartCriteriaSkeleton from "../../components/skeleton/ChartCriteriaSkeleton.vue";
import PredictiveAnalyticsService from "../../services/echarts/predictive.analytics.service";
import WordCloudService from "../../services/word.cloud.service";
import ReportService from "../../services/report.service";
import PrintAndCopyService from "../../services/print.and.copy.service";
import TableService from "../../services/table.service";
import ChartService from "../../services/chart.service";
import ClientService from "../../services/client.service";
import EChartsService from "../../services/echarts.service";
import UserService from "../../services/user.service";
import SurveyService from "../../services/survey.service";
import AdminService from "../../services/admin.service";
import MetricsService from "../../services/metrics.service";
import ContentService from "../../services/content.service";
import GoogleService from "../../services/google.service";
import ContentMixins from "../../mixins/ContentMixins.vue";
import ChartCompanyMixins from "../../mixins/ChartCompanyMixins.vue";
import ChartMenu from "../../views/menu/ChartMenu.vue";
import DataTableMixins from "../../mixins/DataTableMixins.vue";
import ChartCriteriaMixins from "../../mixins/ChartCriteriaMixins.vue";
import ChartCriteriaResults from "../../components/chart/ChartCriteriaResults.vue";
import ChartCriteriaOption from "../../components/chart/ChartCriteriaOption.vue";
import SaveChart from "../../components/chart/SaveChart.vue";
import CountsByLocation from "../../modals/CountsByLocation.vue";
import PredictiveAnalyticsContributionChart from "../../views/echarts/PredictiveAnalyticsContributionChart.vue";
import ProfileChartHeader from "../../components/echarts/ProfileChartHeader.vue";
import PredictiveAnalyticsChart from "../../views/echarts/PredictiveAnalyticsChart.vue";
import PredictiveAnalyticsChartButtons from "../../components/echarts/PredictiveAnalyticsChartButtons.vue";
//import ProfileChartHeader from "../../components/echarts/ProfileChartHeader.vue";
import Treeselect, { DirectionType, IconsType, OptionType, TreeselectValue } from 'vue-treeselectjs'
import 'vue-treeselectjs/dist/vue-treeselectjs.css'
import Multiselect from "vue-multiselect";

export default {
  name: "PredictiveAnalytics",
  mixins: [ContentMixins, ChartCompanyMixins, DataTableMixins, ChartCriteriaMixins],
  components: {
    ChartMenu,
    ChartCriteriaResults,
    ChartCriteriaOption,
    SaveChart,
    CountsByLocation,
    PredictiveAnalyticsContributionChart,
    ChartCriteriaSkeleton,
    PredictiveAnalyticsChart,
    PredictiveAnalyticsChartButtons,
    ProfileChartHeader,
    Treeselect,
    Multiselect
  },
  props: {},
  data() {
    return {
      display: false,
      disableGenerate: false,
      displaySecondAxisOnTable: false,
      pageNumber: 6,
      metricGroupId: "0",
      secondaryMetricGroupId: "-1",
      baseMetricGroupId: 0,
      includePartialResponses: false,
      startPeriod: 0,
      endPeriod: 0,
      basePeriod: 0,
      period_options: [],
      metric_options: [],
      useMetricGrowth: false,
      useMetricGrowthForSecondary: false,
      chartTitle: "Predictive Analytics",
      title: "Predictive",
      metricsTableContainerId: "metrics-table",
      analyticsTableContainerId: "predictive-analytics-table", 
      profileTableContainerId:"needs-table",
      chart: {
        title: "Predictive",
        typeName: "predictive-analytics"
      },
      metricsData: {},
      predictiveAnalyticsData: {
        rowsMap: {}
      },
      needsData: {
        rowsMap: {}
      },
      primaryMetric:{
        metricGroupId: 0,
        useMetricGrowth: false,
        isPrimary: true,
        isSecondary: false,
        isAdditional: false,
      },
      secondaryMetric:{
        metricGroupId: 0,
        useMetricGrowth: false,
        isPrimary: false,
        isSecondary: true,
        isAdditional: false,
      },
      positiveClass: "text-success",
      negativeClass: "text-danger",
      baseClass: "predictive-column",
      notActiveClass: "",
      metricsTable: MetricsService.getPredictiveTableAttributes("metrics-table"),
      predictiveAnalyticsTable: MetricsService.getPredictiveTableAttributes("predictive-analytics-table"),
      needsTable: MetricsService.getPredictiveTableAttributes("needs-table"),
      snapModalContent: PrintAndCopyService.getSnapMessage(),
      contributionChartList: [],
      eChartCriteria: this.getDefaultEChartCriteria(),
      selectedAdditionalSecondaryMetrics: [],
    };
  },
  methods: {
    closeModal(name) {
      if(this.$refs[name] != null) {
        this.$refs[name].visible = false;
      }
    },
    async snapChart(displayLegend, snapContainerId) {
      PrintAndCopyService.initializeSnapChart(displayLegend, snapContainerId, this); 
    },
    displayInsights(insightModalId, reportPageTypeName) {
      if (reportPageTypeName == "Metric Trends") {
        this.$refs.insightsMetricsTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.reportPagesList);
        this.$refs.insightsMetricsTable.initializeInsights(this.clientChart);
        this.$modal.show(insightModalId);
      } else if (reportPageTypeName == "Predictive Analytics") {
        this.$refs.insightsPredictiveAnalyticsTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.reportPagesList);
        this.$refs.insightsPredictiveAnalyticsTable.initializeInsights(this.clientChart);
        this.$modal.show(insightModalId);
      } else if (reportPageTypeName == "Profile Trends") {
        this.$refs.insightsProfileTable.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.reportPagesList);
        this.$refs.insightsProfileTable.initializeInsights(this.clientChart);
        this.$modal.show(insightModalId);
      } else if (reportPageTypeName == "Chart - Impact") {
        this.$refs.insightsChartImpact.reportPageInput = ReportService.getReportPage(reportPageTypeName, this.reportPagesList);
        this.$refs.insightsChartImpact.initializeInsights(this.clientChart);
        this.$modal.show(insightModalId);
      }
    },
    clearMetricsTable(event, tableType = 0) {
      if (typeof event != "undefined") {
        let newValue = event.target.value;
        if (typeof this.metricsTable.dataTable != "undefined" && this.metricGroupId != this.secondaryMetricGroupId) {
          this.metricsTable.dataTable.destroy();
          this.metricsData = {};
        } else if (tableType == 2 && this.metricGroupId == newValue){
          this.secondaryMetricGroupId = -1;
        } else if (tableType == 1 && this.secondaryMetricGroupId == newValue){
          this.secondaryMetricGroupId = -1;
        } 
        if (newValue != -1) {
          this.removeAdditionalSecondaryMetricByValue(newValue);
        } else {
          for (let i = 0; i < this.selectedAdditionalSecondaryMetrics.length; i++) {
            let metric = this.selectedAdditionalSecondaryMetrics[i];
            this.removeAdditionalSecondaryMetric(metric);            
          }
          this.selectedAdditionalSecondaryMetrics = [];
        }
        
        
      }
    },
    clearTable() {
      this.clearMetricsTable();
      if (typeof this.predictiveAnalyticsTable.dataTable != "undefined") {
        this.predictiveAnalyticsTable.dataTable.destroy();
        this.predictiveAnalyticsData = {};
        this.predictiveAnalyticsData.rowsMap = {};
      }         
      if (typeof this.needsTable.dataTable != "undefined") {
        this.needsTable.dataTable.destroy();
        this.needsData = {};
        this.needsData.rowsMap = {};
      }       
      this.setDisplayView(false);
    },
    resetChartCriteria: function() {
      this.resetOption = !this.resetOption;
      this.startPeriod = this.basePeriod;
      this.endPeriod = this.basePeriod;
      this.metricGroupId = this.baseMetricGroupId;
      this.secondaryMetricGroupId = "-1";
      for (let i = 0; i < this.selectedAdditionalSecondaryMetrics.length; i++) {
        let itemToRemove = this.selectedAdditionalSecondaryMetrics[i];
        this.removeAdditionalSecondaryMetric(itemToRemove);
      }
      this.selectedAdditionalSecondaryMetrics = [];
      this.surveyGroup = "";
      this.includePartialResponses = false;
      this.autoGenerateChartRun = false;
      this.surveyLocation = [];
      this.words = [];
      this.resetCriteriaResults();
      GoogleService.logResetCriteria(this.chart.title, "", this.survey.name, this);
      this.eChartCriteria = this.getDefaultEChartCriteria();
    },
    processAutoGenerateChart() {
      if (this.autoGenerateChartRun) {
        if (typeof this.clientChart != "undefined") {
          if (typeof this.clientChart.criteria != "undefined" && this.clientChart.criteria != "") {
            let criteriaParameters = this.clientChart.criteria.split("&");
            for (let criteriaIndex = 0; criteriaIndex < criteriaParameters.length; criteriaIndex++) {
              let criteria = criteriaParameters[criteriaIndex];
              this.processSavedChartCriteria(criteria);
            }
          }
          this.metricGroupId = this.generateMetricGroupId(this.metricGroupId, this.useMetricGrowth);
          this.secondaryMetricGroupId = this.generateMetricGroupId(this.secondaryMetricGroupId, this.useMetricGrowthForSecondary);
          this.display = true;
          this.getDetailedPredictiveAnalytics();
        }
      }
      this.display = true;
    },
    setAdditionalSecondaryMetrics(ids){
      // loop through the ids and set the selectedAdditionalSecondaryMetrics
      for (let i = 0; i < ids.length; i++) {
        let id = ids[i];
        let metric = this.additional_secondary_metric_options.find(metric => metric.value == id);
        if (typeof metric != "undefined") {
          this.selectedAdditionalSecondaryMetrics.push(metric);
        }
      }
    },
    processSavedChartCriteria(criteria) {
      let criteriaParameters = criteria.split("=");
      let defaultParameter = criteriaParameters[0];
      let defaultValue = criteriaParameters[1];
      let defaultCriteria = this.findCriteriaByParameter(defaultParameter);
      if (defaultParameter == "useMetricGrowth") {
          this.useMetricGrowth = criteriaParameters[1].toLowerCase() === "true";
      }
      if (defaultParameter == "useMetricGrowthForSecondary") {
          this.useMetricGrowthForSecondary = criteriaParameters[1].toLowerCase() === "true";
      }
      if (defaultParameter == "secondaryMetricGroupId") {
        this.secondaryMetricGroupId = defaultValue;
      }
      // Process Saved Survey Locations
      if (defaultParameter == "surveyLocation") {
        let savedSurveyLocations;
        try {
          savedSurveyLocations = JSON.parse(defaultValue);
        } catch (e) {
          var decodedSurveyLocations = decodeURIComponent(defaultValue).replace(/\+/g, " ");
          savedSurveyLocations = decodedSurveyLocations.split(',');
        }        
        this.surveyLocation = [...savedSurveyLocations];
      }
      // Process Saved Additional Secondary Metrics
      if (defaultParameter == "additionalSecondaryMetricIds") {
        let savedAdditionalSecondaryMetricIds;
        try {
          savedAdditionalSecondaryMetricIds = JSON.parse(defaultValue);
        } catch (e) {
          var decodedAdditionalSecondaryMetricIds = decodeURIComponent(defaultValue).replace(/\+/g, " ");
          savedAdditionalSecondaryMetricIds = decodedAdditionalSecondaryMetricIds.split(',');
        }        
        this.setAdditionalSecondaryMetrics(savedAdditionalSecondaryMetricIds);
      }
      if (defaultParameter == "words") {
       let savedWords;
        try {
          savedWords = JSON.parse(defaultValue);
        } catch (e) {
          var decodedSavedWords = decodeURIComponent(defaultValue).replace(/\+/g, " ");
           savedWords = decodedSavedWords.split(',');
        }        
        this.words = [...savedWords];
      }
      if (defaultParameter == "segments") {
        let currentSegments = this.segments;
        let savedSegments;
        try {
          savedSegments = JSON.parse(defaultValue);
        } catch (e) {
          var decodedDefaultValue = decodeURIComponent(defaultValue).replace(/\+/g, " ");
          savedSegments = $.parseJSON(decodedDefaultValue);
        }
        for (let currentSegmentsIndex = 0; currentSegmentsIndex < currentSegments.length; currentSegmentsIndex++) {
          let currentSegment = currentSegments[currentSegmentsIndex];
          for (let savedSegmentsIndex = 0; savedSegmentsIndex < savedSegments.length; savedSegmentsIndex++) {
            let savedSegment = savedSegments[savedSegmentsIndex];
            if (currentSegment.name == savedSegment.name) {
              currentSegment.values = savedSegment.values;
            }
          }
        }
      }      
      if (defaultParameter == "showLabels"){
        let showLabels = "";
        try {
          showLabels = JSON.parse(defaultValue);
        } catch (e) {
          showLabels = decodeURIComponent(defaultValue).replace(/\+/g, " ");
        }   
        this.eChartCriteria.showLabels = showLabels || (showLabels == "true");   
      }
      if (typeof defaultCriteria != "undefined") {
        let isStartPeriod = defaultCriteria.parameterName == "startPeriod" ? true : false;
        let isEndPeriod = defaultCriteria.parameterName == "endPeriod" ? true : false;
        let isMetricGroupId = defaultCriteria.parameterName == "metricGroupId" ? true : false;
        let isSurveyGroup = defaultCriteria.parameterName == "survey_group" ? true : false;
        let isShowPublicGroups = defaultCriteria.parameterName == "show_public_groups" ? true : false;
        let isUseEqualWeight = defaultCriteria.parameterName == "use_equal_weight" ? true : false;        
        let updateValue = isStartPeriod || isEndPeriod || isMetricGroupId;
        if (isStartPeriod) {
          this.startPeriod = parseInt(defaultValue);
        }
        if (isEndPeriod) {
          this.endPeriod = parseInt(defaultValue);
        }
        if (isMetricGroupId) {
          this.metricGroupId = defaultValue;
        }
        if (isUseEqualWeight) {
          this.useEqualWeight = defaultValue;
        }
        if (isShowPublicGroups) {
          this.showPublicGroups = defaultValue;
          this.survey_group_options = this.getSurveyGroupOptions();
        }
        if (isSurveyGroup) {
          this.surveyGroup = defaultValue;
        }        
        this.saveResultValue(defaultValue, defaultCriteria.name, defaultCriteria.parameterName, updateValue);
      }
    },
    async getDetailedPredictiveAnalytics() {
      this.$root.processServerRequest("Retrieving data");
      this.setDisplayView(false);
      let params = this.buildClientChart(this.buildBaseCriteriaUrl);
      GoogleService.logGenerateChart(this.chart.title, "", this.survey.name, this.autoGenerateChartRun, this);
      ClientService.getDetailedPredictiveAnalytics(params, this.getDetailedPredictiveAnalyticsCallback, this);
    },
    buildBaseCriteriaUrl() {
      let params = new URLSearchParams();
      params.append("clientCode", ClientService.getClientCode());
      params.append("surveyId", SurveyService.getSurveyId());
      params.append("userId", UserService.getUserId());
      params.append("startPeriod", this.startPeriod);
      params.append("endPeriod", this.endPeriod);
      params.append("metricGroupId", this.parseMetricGroupId(this.metricGroupId,"primary"));
      params.append("secondaryMetricGroupId", this.parseMetricGroupId(this.secondaryMetricGroupId,"secondary"));
      params.append("additionalSecondaryMetricIds", this.selectedAdditionalSecondaryMetrics.map(({value}) => value));
      params.append("includePartialResponses", this.includePartialResponses);
      params.append("useMetricGrowth", this.useMetricGrowth);
      params.append("useMetricGrowthForSecondary", this.useMetricGrowthForSecondary);
      params.append("showLabels",this.eChartCriteria.showLabels);
      this.primaryMetric.metricGroupId = this.metricGroupId;
      this.secondaryMetric.metricGroupId = this.secondaryMetricGroupId;
      this.primaryMetric = this.setMetric(toRaw(this.primaryMetric));
      this.secondaryMetric = this.setMetric(toRaw(this.secondaryMetric));
      params.append("primaryMetric",JSON.stringify(this.primaryMetric));
      params.append("secondaryMetric",JSON.stringify(this.secondaryMetric));
      params.append("additionalSecondaryMetricsIds", this.additionalSecondaryMetricsIds);
      return this.buildCriteriaUrl(params);
    },
    generateMetricGroupId: function(inputMetricGroupId, inputUseMetricGrowth) {
      let returnMetricGroupId = inputMetricGroupId;
      if (inputUseMetricGrowth == true &&  !inputMetricGroupId.includes("growth")) {
        returnMetricGroupId = inputMetricGroupId+"-growth";
      }
      return returnMetricGroupId;
    },
    setMetric: function(metricObject){
      let inputMetricGroupId = metricObject.metricGroupId;
      if (inputMetricGroupId == "-1" || inputMetricGroupId == "0" || inputMetricGroupId == "") {
        return metricObject;
      }
      if (inputMetricGroupId.includes("growth")) {
        metricObject.useMetricGrowth = true;
        metricObject.metricGroupId = inputMetricGroupId.replace("-growth", "");
      } else {
        metricObject.useMetricGrowth = false;
      }
      return metricObject;
    },
    parseMetricGroupId: function(inputMetricGroupId,type) {
      if (inputMetricGroupId == "-1" || inputMetricGroupId == "0" || inputMetricGroupId == "") {
        return inputMetricGroupId;
      }
      if (inputMetricGroupId.includes("growth")) {
        if (type == "primary") {
          this.useMetricGrowth = true;
        } else {
          this.useMetricGrowthForSecondary = true;
        }
        inputMetricGroupId = inputMetricGroupId.replace("-growth", "");
      } else {
        if (type == "primary") {
          this.useMetricGrowth = false;
        } else {
          this.useMetricGrowthForSecondary = false;
        }
      }
      return inputMetricGroupId;
    },   
    saveProfileDataTable(){
      this.saveAllReportPages();
    }, 
    buildClientChartForPredictiveAnalytics: function(){
      this.buildClientChart(this.buildBaseCriteriaUrl);
      this.autoGenerateChartRun = false;
    },
    buildClientChart: function(buildURLFunction) {
      let useSaveParameters = typeof this.clientChart.criteria != undefined && this.clientChart.criteria != "" && this.autoGenerateChartRun;
      const params = useSaveParameters ? new URLSearchParams(this.clientChart.criteria) : buildURLFunction();
      this.clientChart.criteria = params.toString();
      this.clientChart.chartTypeId = this.chartType.chartTypeId;
      this.clientChart.clientId = this.clientId;
      this.clientChart.clientTimePeriodId = this.startPeriod;
      if (typeof this.clientChart.displayType != "undefined" && typeof this.$refs.chartCriteria != "undefined") {
        this.clientChart.displayType = this.$refs.chartCriteria.$data.displayType;
      } else {
        this.clientChart.displayType = "analytics";
      }
      if (typeof this.saveClientChartObject.name == "undefined" || this.saveClientChartObject.name == "") {
        this.clientChart.name = this.metroGroupName + " Analytics ";
      }
      if (!this.displayChartOnly) {
        this.autoGenerateChartRun = false;
      }
      return params;
    },
    isNumber(value) {
      return value != "--";
    },
    getReportTableContentForPredictiveAnalytics(containerId) {
      let reportTableId = containerId + "_wrapper";
      return this.getReportTableContentByTable(reportTableId);
    },
    getRowData(inputRows){
      let rowData = [];
      let row = [];
      for (let inputRowindex = 0; inputRowindex < inputRows.length; inputRowindex++) {
        let inputRow = inputRows[inputRowindex];
        for (let index = 0; index < inputRow.length; index++) {
          row.push(inputRow[index].data);
        }
        rowData.push(row);
        row = [];
      }
      return rowData;
    },
    getMetricData(){
      let rowData = [];
      let row = [];
      for (let index = 0; index < this.metricsData.metricGrowthRow.length; index++) {
        row.push(this.metricsData.metricGrowthRow[index].data);
      }
      rowData.push(row);
      row = [];
      for (let index = 0; index < this.metricsData.metricRow.length; index++) {
        row.push(this.metricsData.metricRow[index].data);
      }
      rowData.push(row);

      if (this.displaySecondAxisOnTable && this.useMetricGrowthForSecondary){      
        row = [];
        for (let index = 0; index < this.metricsData.secondaryMetricGrowthRow.length; index++) {
          row.push(this.metricsData.secondaryMetricGrowthRow[index].data);
        }
        rowData.push(row);
      }

      if (this.displaySecondAxisOnTable && !this.useMetricGrowthForSecondary){      
        row = [];
        for (let index = 0; index < this.metricsData.secondaryMetricRow.length; index++) {
          row.push(this.metricsData.secondaryMetricRow[index].data);
        }
        rowData.push(row);
      }

      return rowData;
    },
    getColumnData(tableHeader){
      let columnData = [];
      for (let index = 0; index < tableHeader.length; index++) {
        columnData.push(tableHeader[index].data);
      }
      return columnData;
    },
    getTableData(rowData,columnData){
      let tableData = {
        rows: rowData,
        columns: columnData,
      }
      return JSON.stringify(tableData);
	  },    
    async saveReportPage(containerId, reportPageTableName) {
      if (this.clientChart.clientChartId != 0   && this.isGenerateReportRunning()) {
        let tableData;
        if (reportPageTableName == "Metric Trends"){
          let metricsRowData = this.getMetricData();
          let metricsColumnData = this.getColumnData(this.metricsData.tableHeader);
          tableData = this.getTableData(metricsRowData,metricsColumnData)

        } else if (reportPageTableName == "Predictive Analytics") {
          let predictiveAnalyticsRowData = this.predictiveAnalyticsTable.dataTable.rows().data().toArray();
          let predictiveAnalyticsColumnData = this.getColumnData(this.predictiveAnalyticsData.tableHeader);
          tableData = this.getTableData(predictiveAnalyticsRowData,predictiveAnalyticsColumnData)
        } else if (reportPageTableName == "Profile Trends") {
          let needsRowData = this.getRowData(this.needsData.rowsMap.rows);
          let needsColumnData = this.getColumnData(this.needsData.tableHeader);
          tableData = this.getTableData(needsRowData,needsColumnData)
        }
        await ReportService.saveReportTableFile(
          tableData,
          reportPageTableName,
          this.clientChart,
          this.saveReportTableFileCallback,
          this
        );
  
        await ReportService.saveReportPage(
          this.getReportTableContentForPredictiveAnalytics(containerId),
          reportPageTableName,
          this.clientChart,
          this.saveReportPageCallback,
          this
        );

      }
    },
    async saveAllReportPages() {
      await this.saveReportPage(this.metricsTableContainerId, "Metric Trends");
      await this.saveReportPage(this.analyticsTableContainerId, "Predictive Analytics");
      await this.saveReportPage(this.profileTableContainerId, "Profile Trends");
    },
    async saveContributionReportPages(){
      if (this.clientChart.clientChartId != 0   && this.isGenerateReportRunning()) {
          let containerId = 'p-contribution-snap-id';
          let reportPageName = 'Contribution';
          let reportPageChart = $("#" + containerId);
          let reportPageChartObject = reportPageChart[0];
          let imageReportPageChartContent = await this.createChartImage(reportPageChartObject, false, false);
          await ReportService.saveReportPage(imageReportPageChartContent, reportPageName, this.clientChart, this.saveReportPageCallback, this);          
          await ReportService.saveReportPageFile(this.imageUrl, "png", reportPageName, this.clientChart, this.saveReportPageCallback, this);
      }
    },
    getDetailedPredictiveAnalyticsCallback(response) {
      let tableTimeoutValue = 500;
      this.$root.processServerResponse(false);
      this.setDisplayView(true);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.criteriaResultsList = AdminService.removeByName(response.data.data.criteriaList, "Competitor");
        this.chartTitle = "Metric Trends";
        this.displaySecondAxisOnTable = response.data.data.displaySecondAxisOnTable;
        this.metricsData = response.data.data.metricsData;
        this.predictiveAnalyticsData = response.data.data.predictiveAnalyticsData;
        this.needsData = response.data.data.needsData;
        let orderIndex = response.data.data.predictiveAnalyticsData.initialOrderIndex;
        this.predictiveAnalyticsTable.configuration.order = [[orderIndex, "desc"]];
        this.metricsTable.configuration.order = [[1, "asc"]];
        this.contributionChartList = [];
        let tempContributionChartList = JSON.parse(JSON.stringify(response.data.data.contributionChartList));
        this.contributionChartList = EChartsService.setContributionChartList(tempContributionChartList);
        
        let vm = this;
        setTimeout(() => {
          var tableSelector = "#" + vm.metricsTable.id;
          let isSavedCard = vm.clientChart.clientChartId != 0;
          if (!$.fn.DataTable.isDataTable(tableSelector) || typeof vm.metricsTable.dataTable == "undefined") {
            $.extend(vm.metricsTable.configuration, TableService.getTableButtons(this, "metric-trends-data-container", "metrics-table", "Metric Trends",isSavedCard));
            vm.metricsTable.dataTable = $("#" + vm.metricsTable.id).DataTable(vm.metricsTable.configuration);
          }      

          let metricsTable = $("#" + vm.metricsTable.id);
          let html = "<div class='title pb-0'><h4 class='text-primary mb-0'>Metric Trends</h4></div>"
          metricsTable
            .parent()
            .find(".chart-header-container")
            .html(html);
          let metricsHeaderContainer = $("#metric-trends-data-container")
          .find(".metrics-table-header-container");

          if (metricsHeaderContainer.length > 1) {
            metricsHeaderContainer[0].remove();
          }         
          metricsTable.show();
          vm.saveReportPage(vm.metricsTableContainerId, "Metric Trends",vm.metricsTable.dataTable);
          tableSelector = "#" + vm.predictiveAnalyticsTable.id;
          if (!$.fn.DataTable.isDataTable(tableSelector) || typeof vm.predictiveAnalyticsTable.dataTable == "undefined") {            
            $.extend(
              vm.predictiveAnalyticsTable.configuration,
              TableService.getTableButtons(this, "analytics-data-container", "predictive-analytics-table", "Predictive Analytics",isSavedCard)
            );
            vm.predictiveAnalyticsTable.dataTable = $("#" + vm.predictiveAnalyticsTable.id).DataTable(vm.predictiveAnalyticsTable.configuration);
          }             
          vm.predictiveAnalyticsTable.dataTable.clear();
          for (let rowIndex = 0; rowIndex < vm.predictiveAnalyticsData.rowsMap.rows.length; rowIndex++) {
            let row = vm.predictiveAnalyticsData.rowsMap.rows[rowIndex];
            let filteredRow = [];
            for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
              let columnData = row[columnIndex].data;
              filteredRow.push(columnData);
            }
            vm.predictiveAnalyticsTable.dataTable.row.add(filteredRow);
          }
          vm.predictiveAnalyticsTable.dataTable.draw(true);

          let predictiveAnalyticsTable = $("#" + vm.predictiveAnalyticsTable.id);
          predictiveAnalyticsTable
            .parent()
            .find(".chart-header-container")
            .html("<div class='title'><h4 class='text-primary mb-0'>Analytics</h4></div>");

          let analyticsHeaderContainer = $("#analytics-data-container")
          .find(".predictive-analytics-table-header-container");

          if (analyticsHeaderContainer.length > 1) {
            analyticsHeaderContainer[0].remove();
          } 
          predictiveAnalyticsTable.show();
          vm.saveReportPage(vm.analyticsTableContainerId, "Predictive Analytics",vm.predictiveAnalyticsTable.dataTable);
          tableSelector = "#" + vm.needsTable.id;
          if (!$.fn.DataTable.isDataTable(tableSelector) || typeof vm.needsTable.dataTable == "undefined") {
            vm.needsTable.configuration.order = [
              [0, "desc"],
              [1, "asc"]
            ];
            $.extend(vm.needsTable.configuration, { orderFixed: [[0, "desc"]] });
            $.extend(vm.needsTable.configuration, TableService.getTableButtons(this, "profile-trends-title-and-chart", "needs-table", "Profile Trends",isSavedCard));
            vm.needsTable.dataTable = $("#" + vm.needsTable.id).DataTable(vm.needsTable.configuration);
          }             
          let needsTable = $("#" + vm.needsTable.id);
            needsTable
              .parent()
              .find(".chart-header-container")
              .html("<div class='title'><h4 class='text-primary mb-0'>Profile Trends</h4></div>");

          let needsHeaderContainer = $("#profile-trends-data-container")
          .find(".needs-table-header-container");

          if (needsHeaderContainer.length > 1) {
            needsHeaderContainer[0].remove();
          } 

          needsTable.show();
          vm.saveReportPage(vm.profileTableContainerId, "Profile Trends",vm.needsTable.dataTable);
          let useMetricGrowth = response.data.data.useMetricGrowth;
          let useMetricGrowthForSecondary = response.data.data.useMetricGrowthForSecondary;
          let copyOfData = JSON.parse(JSON.stringify(response.data));
          vm.parseForPredictiveAnalytics(copyOfData,useMetricGrowth,useMetricGrowthForSecondary);
          vm.$refs.eChart.processChart(true,"predictive-analytics",vm.eChartData,vm.eChartCriteria);
          vm.saveContributionReportPages();

          
        }, tableTimeoutValue);
        setTimeout(() => {
          vm.generateNextClientChartLink();
        }, 6000);
      } else if (statusCode == 201) {
        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 },true);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    parseForPredictiveAnalytics:function(inputData,useMetricGrowth,useMetricGrowthForSecondary){
      let itemsToRemove = {
          lastColumns: 0,
          firstRows: 1,
          firstColumns: 0
        };
      let data = {};
      let chart1Data = inputData;
      data = PredictiveAnalyticsService.parseData(1,chart1Data,data,itemsToRemove,useMetricGrowth,useMetricGrowthForSecondary);
      data = PredictiveAnalyticsService.parseLegendAndColorData(chart1Data,data);
      this.eChartData = JSON.parse(JSON.stringify(data));
    },    
    getChartCriteriaOptionsCallback(response) {
      this.$root.processServerResponse(false);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.metricGroupId = response.data.data.metricGroupId;
        this.baseMetricGroupId = response.data.data.metricGroupId;
        this.secondaryMetricGroupId = response.data.data.secondaryMetricGroupId;
        if (typeof response.data.data.startTimePeriod != "undefined") {
          this.startPeriod = response.data.data.startTimePeriod;
          this.basePeriod = response.data.data.startTimePeriod;
        }
        if (typeof response.data.data.endTimePeriod != "undefined") {
          this.endPeriod = response.data.data.endTimePeriod;
          this.basePeriod = response.data.data.startTimePeriod;
        }
        this.period_options = response.data.data.clientTimePeriods;
        let additionalOptions = response.data.data.additionalOptions;
        this.criteriaResults = [];
        for (var i in additionalOptions) {
          this.criteriaResults.push(additionalOptions[i]);
        }
        this.surveyGroupList = response.data.data.surveyGroupList;
        this.segments = ChartService.getSegments(this.criteriaResults);
        this.survey_group_options = this.getSurveyGroupOptions();
        this.processAutoGenerateChart();
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    getMetricGroupByClientIdCallback(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        MetricsService.setMetricDropdownOptions(response.data.data,this, true);
        this.disableGenerate = false;
      } else if (statusCode == 201) {
        this.disableGenerate = false;
        AdminService.displaySuccessMessage({ text: response.data.message, duration: -1 },true);
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }
    },
    async initializeData() {
      this.setDisplayView(false);
      if (!this.$parent.autoGenerateChartRun) {
        this.criteriaResults = [];
        this.resetChartCriteria(false);
      }
      this.clearTable();
      await MetricsService.getMetricGroupBySurveyCode(SurveyService.getSurveyCode(), this.getMetricGroupByClientIdCallback, this);
      await ClientService.getPredictiveAnalyticsOptions(this.getChartCriteriaOptionsCallback, this);
      this.setUseEqualWeight();
    },
    async getPageContent(pageName) {
      ContentService.getPageContent(pageName, this.getPageContentCallBack, this);
    },    
    getPageContentCallBack(response) {
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.pageContent = [...this.pageContent, ...response.data.data];
      }
    },
    setShowLabels(inputShowLabels){
      this.eChartCriteria.showLabels = inputShowLabels;
      this.$refs.eChart.processChart(true,"predictive-analytics", this.eChartData, this.eChartCriteria);
    },
    getDefaultEChartCriteria: function(){
      return EChartsService.getBaseEChartCriteria();
    },
    removeAdditionalSecondaryMetricByValue(value) {
      var match = this.selectedAdditionalSecondaryMetrics.find(x => x.value == value);
      if (typeof match !== "undefined") {
        this.selectedAdditionalSecondaryMetrics.splice($.inArray(match, this.selectedAdditionalSecondaryMetrics), 1);
      }  
    },
    removeAdditionalSecondaryMetric(selectedMetric) {
      if (typeof selectedMetric !== "undefined") {
        selectedMetric.checked = false;
      }       
    },
    addAdditionalSecondaryMetric(id) {
      id.checked = true;
    },
    
  },
  created() {
    this.initializeData();
  },
  mounted() {
    this.metricsTable.configuration.order = [[1, "asc"]];
    this.getPageContent("Chart");
  },
  watch: {
    async surveyCode() {
      this.initializeData();
    },
    async endPeriod(){
      await WordCloudService.getWordCounts(SurveyService.getSurveyId(), this.endPeriod, true, this.getWordCountsCallback);
    },
  },
  computed: {
    disableSecondaryAxisAdditionalMetricDropdown: function() {
      return this.secondaryMetricGroupId == "-1";
    },
    secondary_metric_options: function() {
      return this.metric_options.filter(metricGroup => metricGroup.value != this.metricGroupId);
    },
    additional_secondary_metric_options: function() {
      return this.metric_options.filter(metricGroup => (metricGroup.value != this.metricGroupId && metricGroup.value != this.secondaryMetricGroupId));
      
    },
    additionalSecondaryMetricsIds: function() {
      return this.selectedAdditionalSecondaryMetrics.map(({value}) => value);
    },
    returnMetricTableRows: function(){
      if (typeof this.metricsData.returnMetricsList != "undefined" && this.metricsData.returnMetricsList.length > 0){
        let returnMetricsList = JSON.parse(JSON.stringify(this.metricsData.returnMetricsList));
        returnMetricsList = returnMetricsList.sort((a, b) => a.orderNumber - b.orderNumber);
        let finalReturnMetricsList = returnMetricsList.map(({metricRow}) => metricRow);
        return finalReturnMetricsList;
      }
      return [];
    },
    displayChartOnly: function() {
      return this.secondaryMetricGroupId != "-1";
    },
    metroGroupName: function() {
      let currenMetricGroup = this.metric_options.find(metricGroup => metricGroup.value == this.value);
      if (typeof currenMetricGroup != "undefined") {
        return currenMetricGroup.name;
      }
      return "";
    }
  }
};
</script>

<template>
    <div class="col-12 no-gutters container mt-3">
        <div class="e-chart-container">
            <div class="title-and-chart">
                <div :id="eChartId" class="e-chart">
                    <chart :options="chart.options"></chart>
                </div>
                <div :id="eChartMiniId">
                    <img :src="imageUrl" class="pdf-content-image-width hidden-dashboard-display"/>
                </div>             
            </div>
        </div>
    </div>
</template>

<script>
import ScatterService from "../../services/echarts/scatter.service";
import ReportService from "../../services/report.service";
import RanksService from "../../services/echarts/ranks.service";
import TableService from "../../services/table.service";

import EChartsMixins from "../../mixins/EChartsMixins";

import $, { isEmptyObject } from "jquery";

export default {
  name: "RanksChart",
  mixins: [EChartsMixins],
  components: {},
  data() {
    return {
        charts: [],
        legend : [],
        colors: [],
        addWrapper: false,
    };
  },
  props: {},
  computed: {},
  mounted() {},
  created() {},
  methods: {
    //https://codepen.io/plainheart/pen/bGgmGPj    
    setDataForRanks: function(needsData,chart1,chart2 = {},diplayType){
        this.setDataForBaseChart(needsData,chart1,chart2,diplayType);
        let chart1Data = {};
        let chart2Data = {};        
        if (typeof needsData != "undefined" && !$.isEmptyObject(needsData)) {
            if (typeof needsData.chart1 != "undefined" && needsData.chart1.data.length > 0){
                chart1Data = JSON.parse(JSON.stringify(needsData.chart1));
                if (typeof needsData.chart1.containerId != "undefined"){
                    chart1.containerId = needsData.chart1.containerId;
                }
            }                
            if (typeof needsData.chart2 != "undefined" && needsData.chart2.data.length > 0){
                chart2Data = JSON.parse(JSON.stringify(needsData.chart2));
                if (typeof needsData.chart2.containerId != "undefined"){
                    chart2.containerId = needsData.chart2.containerId;
                }
            }           
        }        
        let displayedLegend = JSON.parse(JSON.stringify(this.legend.normal));
        for (let legendIndex = 0; legendIndex < displayedLegend.length; legendIndex++) {
            let legend = displayedLegend[legendIndex];
            let legendName = legend.name;
            if (legendIndex == 0){
                let series = RanksService.getSeries(legendName,0,0,0,RanksService.getCompanyConfig("diamond",13));
                $.extend(true, series, {markLine: RanksService.getDefaultMarkLineForMoat()});
                chart1.series.push(series);
            } else {
                chart1.series.push(RanksService.getSeries(legendName,0,0,0,RanksService.getBaseConfig("circle")));
            }
        }
        chart1 = this.setChart(chart1Data, chart1);
        this.charts.push(chart1);
        if (!$.isEmptyObject(chart2Data)){
            chart2 = this.setChart(chart2Data, chart2);
            this.charts.push(chart2);
        }
    },
    setChart: function(needsData, chart){
        chart = this.clearChart(chart);
        let xAxisData = [];
        for (let needsIndex = 0; needsIndex < needsData.data.length; needsIndex++) {
            let companyProperties = needsData.data[needsIndex];
            if (companyProperties.length >= 3) {
                let seriesIndex = -1;
                for (let propertyIndex = 0; propertyIndex < companyProperties.length; propertyIndex++) {
                    let property = companyProperties[propertyIndex];
                    if (typeof property != 'undefined' && property != null ) {
                        if (propertyIndex == 1){
                            xAxisData.push(TableService.getMoatLongName(property));
                        } else if (propertyIndex >= 7 && seriesIndex < chart.series.length){
                            seriesIndex++;
                            chart.series[seriesIndex].data.push(property);
                        }
                    }
                }
            }            
        }
        chart.xAxis.data = JSON.parse(JSON.stringify(xAxisData));
        return chart;
    },
    getChartOptions: function(){
        let options = {};
        let showToolbox =  !(ReportService.getReportAutoChartGenerateStatus() == "generate");
        let colorPaletteId = this.eChartCriteria.colorPaletteId;
        let showLegend = this.eChartCriteria.showLegend;
        let showLabel = false;
        let angleText = true;
        let isMultipleWaves = true;
/* Start of function that should go into helper service */
        let xAxis = [];
        let seriesData = [];
        for (let i = 0; i < this.charts.length; i++) {
            let chart = this.charts[i];
            for (let i = 0; i < chart.series.length; i++) {
                let series = chart.series[i];
                seriesData.push(series);
            }
            $.extend(true, chart.xAxis.axisLabel, RanksService.getDefaultAxisTextStyleForAnalyticChart());
            if (angleText == true){           
                $.extend(true, chart.xAxis.axisLabel, {rotate: 25,hideOverlap: true,interval: 0});
            } else {
                let defaultAxisLabel = {
                    overflow: "break",
                    interval: 0,
                    width: 40,
                    ellipsis: '...',        
                    rotate: 0,
                    hideOverlap: false,
                };
                $.extend(true, chart.xAxis.axisLabel, defaultAxisLabel);
            }
            xAxis.push(chart.xAxis);
            xAxis.push(chart.header);
        }
/* End of function that should go into helper service */
        ScatterService.setShowSnap(this.isSavedCard);
        ScatterService.setShowToolbox(showToolbox);
        ScatterService.setColorPaletteId(colorPaletteId);
        ScatterService.setShowLabel(showLabel);
        ScatterService.setShowLegend(showLegend);
        ScatterService.setIsMultiWave(isMultipleWaves);
        ScatterService.setChartType(this.eChartCriteria.eChartType);
        let legendData = this.legend.normal.map(entry => entry.name);
        ScatterService.setLegendData(legendData);
        ScatterService.setSeriesData(seriesData);
        let yAxis = RanksService.getYAxisForNeed(0, 100);
        $.extend(true, yAxis.splitLine,{show: false});
        $.extend(true, yAxis.axisLabel,{formatter: "{value}%"});
        $.extend(true, yAxis, {position: "right"});
        
        ScatterService.setYAxis(yAxis);
        ScatterService.setXAxis(xAxis);
        options = ScatterService.getOptions(this,this.modalId, this.inputReportTypeName);
        if (!$.isEmptyObject(options)) { 
            options.tooltip.backgroundColor = "#ffffff";
            options.tooltip.formatter =  RanksService.toolTipForRanksChart;
            //options.tooltip.trigger =  'item',
            //remove the 1st 4 colors
            this.colors = this.colors.slice(5);
            options.color = this.colors;
            options.series[0].label.color = "#2F5EB3";
            for (let i = 0; i < options.grid.length; i++) {
                options.grid[i].top = 40;
            }
            $.extend(true, options.toolbox, RanksService.getToolboxForAnalyticsChart());
        }
        return options;           
    },
    processChart: function(runChart = false,displayType,needsData,eChartCriteria){
        if (runChart == true) {
            this.displayType = displayType;
            if (typeof eChartCriteria != "undefined") {
                this.eChartCriteria = JSON.parse(JSON.stringify(eChartCriteria));
            }
            // get the splits for charts
            let breakPointList = [1,7];
            let names = ['Value','Design','Lock-Out','Innovation','Routine','Scale','Lock-In','Network','Time','Skill','Brand'];  
            let numberOfNeeds = 11;
            if (displayType == "moat-ranks"){
                let chart1 = {
                    containerId: "",
                    header: RanksService.getBaseNeedsHeader(breakPointList,numberOfNeeds,displayType),
                    series: [],
                    xAxis: RanksService.getXAxisForNeeds(displayType,breakPointList),                
                };
                ScatterService.setGrid(RanksService.getGridForSingleChart());
                this.setDataForRanks(needsData,chart1,{},displayType);
                chart1.series.push(RanksService.getMoatHeaderSeries(displayType,names,numberOfNeeds,0,1,0));                
                this.initChart();
            } 
            if (typeof needsData != "undefined" && typeof eChartCriteria != "undefined" && this.charts.length > 0) {
                this.$emit('save-criteria',true);
                this.$emit('save-table');
            }
        }
    },
  },
  watch: {},
};
</script>

<template>
  <div v-if="isAdmin" class="playground panel-admin-body">
    <div class="title  d-none">
      <div class="d-flex">
        {{ title }}
      </div>
    </div>
    <div class="row pt-3">
      <div class="col-4"> <h4>Generate Report</h4> </div>
    </div>
    <div class="row pt-3">
      <div class="col-4"> 
         <div class="d-flex">
          <button type="button" class="btn btn-primary ml-2" @click="generateReport">Generate Report</button>
         </div>  
      </div>
    </div>
    <div class="row">
      <div v-show="previewEmailClicked && !getDisplayView()" class="text-success advisor-preview-email modal-body">  
            Email is being rendered please hold......
        </div>      
        <div v-show="previewEmailClicked && getDisplayView()" class="advisor-preview-email modal-body">        
            <div>
                <div>From: {{ emailFromAddress }}</div>
                <div>Subject: {{ emailSubject }}</div>
                
            </div>
            <div class="email-body" v-html="emailBody" style="width: 600px;"></div>
        </div>            
    </div>
    <div id="cp-by-functional-need-e-chart" class="chart-row chart-wrapper" style="">
      <div class="e-chart-container" style="width:1075px">
        <div class="title-and-chart" style="width:1075px">      
          <div :id="eChartId" class="e-chart" style="width:1075px">
            <chart :options="chart.options"></chart>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
</template>
<script>
import ViewMixins from "../mixins/ViewMixins.vue";
import AdminMixins from "../mixins/AdminMixins.vue";
import DataTableMixins from "../mixins/DataTableMixins.vue";
import AdminService from "../services/admin.service";
import SurveyService from "../services/survey.service";
import ReportService from "../services/report.service";
import UserService from "../services/user.service";
import ErrorService from "../services/error.service";
import AdvisorService from "../services/advisor.service";
import axios from "axios";
import authHeader from "../services/auth-header";
import EChartsMixins from "../mixins/EChartsMixins.vue";
import $ from "jquery";
import { markRaw } from 'vue'; 
import Echarts from "vue-echarts";
import echarts from "echarts";

export default {
  name: "Playground",
  mixins: [AdminMixins, ViewMixins, DataTableMixins,EChartsMixins],
 components: {},
  props: {},
  data() {
    return {
      title: "At the Playground",
      eChartId: "profile-chart",
      disableButton: false,
      modalLoading: false,
      surveyId: SurveyService.getSurveyId(),
      clientTimePeriodId: 11155,
      currentSurveyName: SurveyService.getSurveyName(),
      surveySelected: true,
      clientReport: {"id":0,"clientReportId":0,"clientId":38175,"title":"Test for Widescreen","subTitle":"Roadrunner","filePath":"Test for Widescreen-Roadrunner.pptx","includeCoverPage":"Y","includeContentPage":"Y","includeEndPage":"Y","pin":"N","clientName":"RoadRunner","dateCreated":"2024-11-06T07:07:01.000+00:00","dateModified":"2024-11-06T07:07:01.000+00:00","clientChartReportPages":[{"clientChartId":1219,"name":"Chart - Needs Profile","include":true},{"clientChartId":1219,"name":"Table - Functional Needs","include":true},{"clientChartId":1219,"name":"Table - Emotional Needs","include":true},{"clientChartId":1220,"name":"Chart - Moat Profile","include":true},{"clientChartId":1220,"name":"Table - Moat Profile","include":true}],"savedClientChartReportPages":[{"id":0,"reportPageTypeId":4,"clientChartId":1219,"sequenceNumber":null,"name":"Chart - Needs Profile","include":true},{"id":0,"reportPageTypeId":5,"clientChartId":1219,"sequenceNumber":null,"name":"Table - Functional Needs","include":true},{"id":0,"reportPageTypeId":7,"clientChartId":1219,"sequenceNumber":null,"name":"Table - Emotional Needs","include":true},{"id":0,"reportPageTypeId":9,"clientChartId":1220,"sequenceNumber":null,"name":"Chart - Moat Profile","include":true},{"id":0,"reportPageTypeId":10,"clientChartId":1220,"sequenceNumber":null,"name":"Table - Moat Profile","include":true}]},
      advisorId: 1782,
      emailBody: "",
      emailFromAddress: "",
      emailSubject: "",
      refreshModal: true ,
      previewEmailClicked: false     
    };
  },
  methods: {
/**** Functions for WRAT-1121 Ability for Users to Download Verbatims - Start*/    
   
    async downloadItem() {
      this.modalLoading = true;
      this.previewEmailClicked = false;
      this.setupDownloadItem();
      await axios({
        url: this.baseUrl + "/api/v1/chart/download-survey-verbatims?survey_id=" + this.surveyId + "&period="+ this.clientTimePeriodId + "&user_id=" + UserService.getUserId(),
        method: "GET",
        responseType: "blob"
      })
      .then(response => this.downloadItemCallback(response))
      .catch(error => this.downloadItemError(error));
      this.modalLoading = false;
    },    
/**** Functions for WRAT-1121 Ability for Users to Download Verbatims - End */        
    previewEmail: function(){
      this.previewEmailClicked = true;
      // preview-us-foods-email
      this.previewUSFoodsEmail(this.advisorId, this.previewAdvisorEmailCallback, this);
    },
    previewUSFoodsEmail: async  function(advisorId, callback, chartApp){
      const params = new URLSearchParams();
      params.append("advisorId", advisorId);
      params.append("userId", UserService.getUserId());
      await axios
        .post( AdminService.getBaseUrl() + "/api/v1/import-export" + "/preview-us-foods-email", params, { headers: await authHeader() })
        .then(response => callback(response))
        .catch(error => {
          error.message += ErrorService.mergeError(error.response);
          ErrorService.processError("Playground.previewUSFoodsEmail", error, "admin", chartApp);
        });
    },    
    previewAdvisorEmailCallback(response){
      this.$root.processServerResponse();
      this.setDisplayView(true);
      let statusCode = response.data.statusCode;
      if (statusCode == 200) {
        this.emailFromAddress = response.data.data.from; 
        this.emailSubject = response.data.data.subject; 
        this.emailBody = response.data.data.body; 
        AdminService.displayInfoMessage({ text: response.data.message, duration: 1000 });
      } else {
        AdminService.displayErrorMessage({ text: response.data.message });
      }      
    },    
    generateReport(){
      ReportService.generatePowerPointReport(this.clientReport, false, this.downloadItemCallback, this);
    },
    getDownloadFilename() {
      let filename = this.clientReport.title;
      if (this.clientReport.subTitle != "") {
        filename += "-" + this.clientReport.subTitle;
      }
      filename += ".pptx";
      return filename;
    },    
    downloadItemCallback(response) {
      this.$root.processServerResponse();
      const blob = new Blob([response.data], { type: "application/pdf" });
      this.clickLink(blob);
      this.setModalLoading(false);
      AdminService.displaySuccessMessage({ text: "Download Complete", duration: 2000 });
      this.$root.processServerRequest("Reloading generated reports and resetting for a new report");      
    },    
  },
  created() {},
  mounted() {}
};
</script>
<style lang="scss">
</style>

<template>
    <div v-if="isAdmin" class="panel-admin-body">
      <div class="title">{{ title }}</div>
      <div class="container performance-summary">
        <div>
          <import-type />
          <div class="row pt-3">
            <h4>Imports</h4>
          </div>
          <div class="row pt-1">
            <div class="col-2">
              <div class="form-group form-inline">
                <label for="startDate" class="mr-1">Start Date</label>
                <datepicker
                  format="MMM dd yyyy"
                  v-model="startDate"
                  :typeable="true"
                  wrapper-class=""
                  input-class="form-control required w-100 bg-white"
                  name="startDate"
                ></datepicker>
              </div>
            </div>
            <div class="col-2">
              <div class="form-group form-inline ml-2">
                <label for="endDate" class="mr-1">End Date</label>
                <datepicker format="MMM dd yyyy" v-model="endDate" :typeable="true" wrapper-class="" input-class="form-control required w-100 bg-white" name="endDate"></datepicker>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group form-inline ml-2">
                <label for="import_type_name" class="mr-1">Import Type</label>
                <select id="import_type_name" name="import_type_name" class="custom-select required" v-model="importTypeName">
                  <option v-for="(import_type_name_options, idx_import_type_name_options) in this.import_type_name_options" v-bind:value="import_type_name_options.value" v-bind:key="idx_import_type_name_options">
                    {{ import_type_name_options.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group form-inline">
                <label for="status" class="mr-1">Status</label>
                <select id="status" name="status" class="custom-select required" v-model="status">
                  <option v-for="(status_option, idxStatusOptions) in this.status_options" v-bind:value="status_option.value" v-bind:key="idxStatusOptions">
                    {{ status_option.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="pr-2">
              <button type="button" class="btn btn-primary ml-2" id="AddNewContentButton" @click="loadData()">Run</button>
            </div>
            <div class="pr-2">
              <button type="button" class="btn btn-primary ml-2" id="AddNewContentButton" @click="reset()">Reset</button>
            </div>
          </div>
          <div class="row w-75">
            <table :id="table.id" :class="table.class" cellspacing="0" width="100%">
              <thead></thead>
              <tbody></tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="panel-admin-body no-permission-list">You do not have permission to view this page</div>
  </template>
  
  <script>
  import Vue from "vue";
  import $ from "jquery";
  
  import moment from "moment";
  Vue.prototype.moment = moment;
  import Datepicker from "vuejs-datepicker";
  import ImportType from "./ImportType.vue";
  import AdminMixins from "../../mixins/AdminMixins.vue";
  import DataTableMixins from "../../mixins/DataTableMixins.vue";
  import AdminService from "../../services/admin.service";
  import TableService from "../../services/table.service";
  import AlertService from "../../services/alert.service";
  
  export default {
    name: "Import",
    mixins: [AdminMixins, DataTableMixins],
    components: { ImportType, Datepicker },
    data() {
      return {
        title: "Import",
        table: TableService.getImportLogTableAttributes(),
        messageTable: TableService.getImportTypeAttributes(),
        startDate: new Date(2020, 0, 1),
        endDate: new Date(),
        jobName: "",
        status: 0,
        job_name_options: [],
        status_options: [
          { name: "Select Status", value: "" },
          { name: "Success", value: "C" },
          { name: "Incomplete", value: "I" },
          { name: "Failed", value: "E" }
          
        ],
        import_type_name_options: []
      };
    },
    methods: {
      reset() {
        this.startDate = new Date(2020, 0, 1);
        this.endDate = new Date();
        this.status = "";
        this.importTypeName = "";
      },
      async getTableData() {
        this.$root.processServerRequest("Loading table data");
        if(typeof this.importTypeName == "undefined") {
            this.importTypeName = "";
        }
        if(this.status == "0") {
            this.status = "";
        }
        AdminService.getImportLogList(this.startDate, this.endDate, this.status, this.importTypeName, this.getImportLogListCallback, this);
      },
      getImportLogListCallback(response) {
        this.$root.processServerResponse();
        this.table.data = response.data.data.importList;
        this.import_type_name_options = response.data.data.importTypeNameOptions;
        $.extend(this.table.configuration, { columns: this.table.columns });
        $.extend(this.table.configuration, { data: this.table.data });
        this.table.dataTable = $("#" + this.table.id).DataTable(this.table.configuration);
        const vm = this;
        this.table.dataTable
          .on("click", "tr", function(e, dt, type, indexes) {
            vm.table.rowSelected = true;
            var rowData = vm.table.dataTable
              .rows(this)
              .data()
              .toArray();
            $("#" + vm.table.id + " .selected").removeClass("selected");
            $(this).toggleClass("selected");
            if (!$.isEmptyObject(rowData)) {
              vm.table.rowSelectedId = rowData[0].id;
              vm.postRowSelect(rowData[0], true);
            }
          })
          .on("deselect", function(e, dt, type, indexes) {
            vm.table.rowSelected = false;
            vm.table.rowSelectedId = 0;
            if ($(this).hasClass("selected")) {
              $(this).removeClass("selected");
            }
            var rowData = vm.table.dataTable
              .rows(this)
              .data()
              .toArray();
            if (!$.isEmptyObject(rowData)) {
              vm.postRowSelect(rowData[0], false);
            }
          });
        AlertService.closeAlert();
        this.buildResponsiveTable();
      },
      loadData() {
        this.getTableData();
        this.table.configuration.order = [[6, "desc"]];
        this.table.configuration.dom = "frtpB";
        this.table.configuration.responsive = false;
      }
    },
    computed: {},
    created() {
      if (this.isAdmin) {
        this.loadData();
      }
    },
    props: {},
    mounted() {}
  };
  </script>
  